import React from "react";
import {Link} from 'react-router-dom'

import classic02 from '../assets/images/saas/classic02.png';
import {MdKeyboardArrowRight, BsCheckCircle} from '../assets/icons/icons'

export default function AboutOne(){
    return(
        <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
            <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                <img src='images/teaching_img1.png' className="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
                <div className="overflow-hidden absolute lg:size-[400px] size-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
            </div>

            <div className="lg:ms-4 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                <h4 className="mb-4 text-2xl leading-normal font-medium">Virtual Teaching Without Studio</h4>
                <p className="text-slate-400">Whether you're an educator, trainer, or workshop facilitator, our platform equips you to deliver high-quality content from anywhere, without the need for a studio service provider. </p>
                <ul className="list-none text-slate-400 mt-4">
                    <li className="mb-2 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/>Teach from the comfort of your home, office, or any location with internet access.</li>
                    <li className="mb-2 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/> No need for expensive studio rentals or equipment. Our platform is budget-friendly.</li>
                    <li className="mb-2 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Engage your audience with live polls, Q&A sessions, and interactive presentations.</li>
                    <li className="mb-2 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Get started quickly with our simple setup process. No technical expertise required.</li>
                    <li className="mb-2 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Connect with students and participants worldwide, breaking down geographical barriers.</li>
                </ul>

                <div className="mt-4">
                    <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl ms-1"/></Link>
                </div>
            </div>
        </div>
    )
}