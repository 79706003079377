import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import about2 from '../../assets/images/company/about2.png';
import cta_bg from '../../assets/images/cta-bg.jpg';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
import Blog2 from '../../component/blog2';
import imageP from '../../assets/images/portfolio/01.jpg';
import image1 from '../../assets/images/portfolio/02.jpg';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';
import KeyFeature from '../../component/keyFeature';
import KeyFeatureTwo from '../../component/keyFeatureTwo';
import app from '../../assets/images/app/app.png';
import playstore from '../../assets/images/app/playstore.png';
import hero from '../../assets/images/course/hero.png';

import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";

import * as Icon from 'react-feather';
import { FaArrowRight, MdKeyboardArrowRight } from '../../assets/icons/icons'


import { featureData } from '../../data/dataFour';
import { accordionData } from '../../data/dataTwo';
import { TypeAnimation } from 'react-type-animation';


export default function IndexLandingFour() {
  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);


  let toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  }
  return (
    <>
      <Navbar />
            <section className="relative table w-full py-36 lg:py-44 bg-indigo-600/5 overflow-hidden">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px] relative">
                        <div className="md:col-span-7">
                            <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">Streamline Your  <br/><span className="text-indigo-600">Sales </span> Process</h4>
                            <p className="text-slate-400 text-lg max-w-xl text-justify">Welcome to the future of checkout! With our Express Checkout feature, we're revolutionizing the way businesses handle transactions.</p>

                            <div className="mt-6">
                                <Link to="/get-started"  className="py-2 px-5  inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link>
                            </div>
                        </div>

                        <div className="md:col-span-5">
                            <div className="relative">
                                <img src='images/express/banner.png' alt="" />
                            </div>
                        </div>

                        <div className="overflow-hidden after:content-[''] after:absolute after:size-32 after:bg-red-600/5 after:top-10 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]"></div>
                        <div className="overflow-hidden after:content-[''] after:absolute after:size-[512px] after:bg-indigo-600/5 after:top-0 after:-end-5 after:-z-1 after:rounded-full"></div>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px] relative">
                        <div className="lg:col-span-12 md:col-span-12">
                            <div className="grid grid-cols-1 pb-8">
                                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">Effortless Express Checkout Process </h3>

                                <p className="text-slate-400  mt-2 width80 m-auto text-center">Enjoy reduced wait times and seamless transactions, empowering your business to thrive in today's fast-paced retail environment.</p>
                            </div>

                            <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                                <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                                    {/* <Icon.Users className="size-12 stroke-1 text-indigo-600"></Icon.Users> */}
                                    <img src="images/icons/one.png" alt="" class="appIcon icon-orange width18"></img>


                                    <div className="content mt-4">
                                        <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">One-Click Payments</Link>
                                        <p className="text-slate-400 mt-3 width80 text-justify">Customers can complete purchases with just one click, saving time and reducing friction.</p>

                                       
                                    </div>
                                </div>

                                <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                                    {/* <Icon.Users className="size-12 stroke-1 text-indigo-600"></Icon.Users> */}
                                    <img src="images/icons/secure-payment.png" alt="" class="appIcon icon-orange width18"></img>


                                    <div className="content mt-4">
                                        <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Secure Payment Processing</Link>
                                        <p className="text-slate-400 mt-3 width80 text-justify">Built-in security measures ensure that transactions are safe and protected against fraud.  </p>

                                       
                                    </div>
                                </div>

                                <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                                    {/* <Icon.Users className="size-12 stroke-1 text-indigo-600"></Icon.Users> */}
                                    <img src="images/icons/customizable.png" alt="" class="appIcon icon-orange width15"></img>


                                    <div className="content mt-4">
                                        <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Customizable Options</Link>
                                        <p className="text-slate-400 mt-3 width80 text-justify">Tailor the checkout process to fit your business needs with customizable features.</p>

                                       
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="overflow-hidden after:content-[''] after:absolute after:h-40 after:w-40 after:bg-red-600/5 after:top-2/4 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]"></div>
                        <div className="overflow-hidden after:content-[''] after:absolute after:size-[512px] after:bg-indigo-600/5 after:top-1/4 after:end-0 after:-z-1 after:rounded-full"></div>
                    </div>
                </div>
            </section>
             
            <section className="relative pb-16 ">
                <div className="container relative">
                    <div className='text-center pb-16'>
                        {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
                        <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Why Choose Us?</h3>
                        <p class="text-slate-400  mt-2 width80 m-auto">Experience lightning-fast transactions with our Express Checkout feature, <br/>streamlining your point-of-sale process for unparalleled efficiency.</p>
                    </div>
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">

                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Cost Savings</h3>
                                <p className="text-slate-400 mt-3 width80 text-justify">Reduce overhead costs associated with manual transaction processing and potential errors, leading to improved bottom-line performance for businesses.</p>
                            </div>
                        </div>
                        <div className="lg:col-span-5 p-2 border shadow radius-4">
                            <img src='images/express/img4.jpg' alt="" />
                        </div>
                    </div>

                 

                    <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
                       
                        <div className="lg:col-span-5 p-2 border shadow radius-4">
                            <img src="images/express/img9.jpg" alt="" />
                        </div>
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">

                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Customizable Options</h3>
                                <p className="text-slate-400 mt-3 width80 text-justify">Tailor the Express Checkout feature to fit specific business needs and preferences, allowing for flexibility and adaptability, enhancing customer loyalty and retention.</p>

                            </div>
                        </div>

                    </div>

                    <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
                        
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">

                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Enhanced Security</h3>
                                <p className="text-slate-400 mt-3 width80 text-justify">Utilize advanced encryption and authentication protocols to safeguard sensitive customer information, ensuring peace of mind for both businesses and customers.</p>

                            </div>
                        </div>
                        <div className="lg:col-span-5 p-2 border shadow radius-4">
                            <img src="images/express/img8.jpg" alt="" />
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
                    <div className="lg:col-span-5 p-2 border shadow radius-4">
                            <img src="images/express/img8.jpg" alt="" />
                        </div>
                    <div className="lg:col-span-7"> 
                            <div className="lg:ms-10">

                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Analytics and Insights</h3>
                                <p className="text-slate-400 mt-3 width80 text-justify">Gain valuable insights into customer behavior and transaction patterns, empowering businesses to make informed decisions and optimize sales strategies.</p>

                            </div>
                        </div>
                       
                        
                    </div>


                </div>
            </section>

            {/* <section className="relative md:py-24 py-16 bg-gray-50">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
                        <div className="lg:col-span-8 md:col-span-6 md:text-start text-center">
                            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Explore Latest Works</h3>
                            <p className="text-slate-400 max-w-xl">Start working with Techwind that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                        <div className=" picture-item rounded-md group  rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
                            <div className="">
                            <div className="relative">
                                <div className=" dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                    <img src='images/express/img3.jpg' className="rounded-t-md shadow " alt="" />
                                </div>
                            </div>

                            
                            </div>
                        </div>

                        <div className=" picture-item rounded-md group  rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
                            <div className="">
                            <div className="relative">
                                <div className=" dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                <img src='images/express/img2.jpg' className="rounded-t-md shadow " alt="" />
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className=" picture-item rounded-md group  rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
                            <div className="">
                            <div className="relative">
                                <div className=" dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                <img src='images/express/img1.jpg' className="rounded-t-md shadow " alt="" />
                                </div>
                            </div>

                            
                            </div>
                        </div>
                        </div>
                </div>
            </section> */}
            

      <section className='py-16 mt-7 w-full table relative bg-indigo-600 mb-16'>
        <div className='container'>
          <h3 class="mb-4 md:text-4xl text-3xl lg:leading-normal leading-normal text-white font-semibold text-center">Ready to Get Started?</h3>
          <p class="text-white/80 max-w-xl mx-auto lg:ms-auto  text-center">Contact us today to learn more about how our innovative payment solution can transform your business.</p>
          <div class="mt-2 d-flex justify-content-center" style={{ justifyContent: "center", display: 'flex' }}>
            <div className="mt-6">
              <Link to="/contact-one" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Contact Us</Link>
              {/* <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Start Free</Link> */}
            </div>
          </div>
        </div>
      </section>



      <Footer />
      <CookieModal />
    </>
  )
}
