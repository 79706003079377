import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import CookieModal from '../../component/cookieModal';



import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";

import * as Icon from 'react-feather';
import axios from "axios";
import SweetAlert2 from 'react-sweetalert2';
import Swal from 'sweetalert2'

export default function IndexLandingFour() {
  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);

  
  const [name,setName] = useState();
  const [email,setEmail] = useState();
  const [question,setQuestion] = useState();
  const [comment,setComment] = useState();




  const handleSubmit = (event) => {
    event.preventDefault();


        let data = JSON.stringify({
        "name": name,
        "email": email,
        "question": question,
        "comment": comment,

        });

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://edukrypt.com/api/sendEmailContactUs',
        headers: {
            'Content-Type': 'application/json'
        },
        data : data
        };

        axios.request(config)
        .then((response) => {

            document.getElementById("create-course-form").reset();
            Swal.fire(response.data.message);



        })
        .catch((error) => {
        console.log(error);
        });


  };


  let toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  }
  return (
    <>
            <Navbar navClass="nav-sticky" />
      <section className="relative table w-full pt-24 pb-36 bg-gray-50 dark:bg-slate-800 overflow-hidden" id="home">
        <div className="container relative">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 mt-10 items-center gap-[30px]">
            <div className="lg:col-span-6 mt-9">
              {/* <span className="bg-indigo-600/5 text-indigo-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">Form Landing Page</span> */}

              {/* <h4 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mt-2 mb-5">Cutting-Edge POS <br/>Software for, </h4> */}
              <h1 className="font-bold heading lg:leading-normal leading-normal text-5xl lg:text-5xl mb-5">Perfecting Fine Dining Service</h1>

              <p className="text-slate-400 text-lg max-w-xl text-justify width90">Enhance your fine dining establishment with Flavour, designed to elevate guest experiences and streamline operations seamlessly.</p>

              <div className="relative mt-10">
              <Link to="/get-started"  className="py-2 px-5  inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link>

                {/* <Link className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full me-5 ms-4"> Support Training</Link> */}

              </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} />

            <div className="lg:col-span-6">
              <div className="dark:bg-slate-900 rounded-xl  dark:shadow-gray-800 md:p-8 p-6 lg:ms-10 z-10 relative">
                {/* <h4 className="mb-5 text-2xl font-semibold">Just Trial Form</h4> */}
                  <div className="relative after:content-[''] after:absolute after:md:bottom-12 after:-bottom-20 lg:after:-start-0 md:after:-start-20 after:-start-24
                    after:bg-indigo-600 after:shadow-2xl after:shadow-indigo-600/40 after:-z-1 ltr:after:rotate-[130deg] rtl:after:-rotate-[130deg] after:w-[75rem] after:md:h-[55rem] after:h-[30rem] after:rounded-[20rem]">
                        <img src='images/dine/banner.png' alt="" />
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      

      <div className="container relative mt-24">
        <div className="grid grid-cols-1 pb-8 text-center">
          {/* <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Features</h6> */}
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Why Choose Us?</h3>

          <p className="text-slate-400  mx-auto">Enhance your fine dining establishment with [designed to elevate guest experiences<br/> and streamline operations seamlessly. </p>
        </div>

        <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/restaurant/restaurant.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Tableside Ordering</Link>
              <p className="text-slate-400 mt-3">Take orders directly at the table using POS  handheld devices, </p>
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/pizza/menu.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600"> Menu Management</Link>
              <p className="text-slate-400 mt-3">Create and manage dynamic menus with item descriptions,  and pricing, </p>
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/restaurant/help-call.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Multi-location </Link>
              <p className="text-slate-400 mt-3">Scale operations across locations with centralized management </p>
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/restaurant/training.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Support and Training</Link>
              <p className="text-slate-400 mt-3"> Receive dedicated support from team and access training resources</p>
            </div>
          </div>

     

          
        </div>
      </div>

      <section className="relative md:py-24 py-16 ">
        <div className="container relative">
          {/* <div className='text-center pb-16'>
            <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Tailored for Fine Dining</h3>
            <p class="text-slate-400  mt-2 width80 m-auto">Experience specialized features such as tableside ordering, split checks, and detailed guest profiles,<br/> all designed to cater to the sophisticated needs of fine dining.</p>
          </div> */}
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Efficiency Redefined</h3>
                <p className="text-slate-400 dark:text-gray-400 text-justify width80">Optimize your workflow with intuitive order management, real-time inventory tracking, and integrated kitchen display systems, ensuring smooth operations from kitchen to table.</p>
              </div>
            </div>
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src='images/dine/img1.jpg' alt="" />
            </div>
          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/dine/img2.jpg" alt="" />
            </div>
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Guest Satisfaction</h3>
                <p className="text-slate-400 dark:text-gray-400 text-justify width80">Elevate service standards with personalized guest preferences, quick order modifications, and seamless communication between front-of-house and kitchen staff.</p>
              </div>
            </div>

          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Data-Driven Insights</h3>
                <p className="text-slate-400 dark:text-gray-400 text-justify width80">Make informed decisions with comprehensive analytics on sales trends, menu performance, and staff productivity, empowering you to refine your strategies and maximize profitability.</p>
              </div>
            </div>
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/dine/img3.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>


        <div className='container'>
            <div className="grid md:grid-cols-12 grid-cols-1 items-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                <div className="md:col-span-6">
                    <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Blogs</h6>
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Reads Our Latest <br /> News & Blog</h3>
                </div>

                <div className="md:col-span-6">
                    <p className="text-slate-400 max-w-xl">Explore Insights and Expertise: Blogs on POS Software for Fine Dining. </p>
                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">
                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden wow animate__animated animate__fadeInUp" >
                    <img src='images/dine/img4.jpg' alt="" />

                    <div className="content p-6">
                        <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Optimizing Table Turnover</Link>
                        <p className="text-slate-400 mt-3 text-justify">Learn strategies to improve table turnover rates using advanced POS features.</p>

                        {/* <div className="mt-4">
                            <Link to="" className="relative inline-flex items-center font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More </Link>
                        </div> */}
                    </div>
                </div>

                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden wow animate__animated animate__fadeInUp" >
                    <img src='images/dine/img5.jpg' alt="" />

                    <div className="content p-6">
                        <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Menu for Profitability</Link>
                        <p className="text-slate-400 mt-3 text-justify">Explore techniques for menu and pricing strategies supported by detailed analytics</p>

                        {/* <div className="mt-4">
                            <Link to="" className="relative inline-flex items-center font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More </Link>
                        </div> */}
                    </div>
                </div>

                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden wow animate__animated animate__fadeInUp" >
                    <img src='images/dine/img6.jpg' alt="" />

                    <div className="content p-6">
                        <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Security Best Practices</Link>
                        <p className="text-slate-400 mt-3 text-justify">Understand the importance of data security in POS systems.</p>

                        {/* <div className="mt-4">
                            <Link to="" className="relative inline-flex items-center font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>



      {/* Contact Form */}
          <div className="container relative mt-16 mb-16">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:me-8">
                            <div className="relative">
                                <img src='images/department/form3.png' alt="" />
                                {/* <div className="overflow-hidden absolute size-[512px] bg-indigo-600/5 top-1/4 start-0 end-0 align-middle -z-1 rounded-full"></div> */}
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-7 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                                <h3 className="mb-6 text-2xl leading-normal font-medium">Get in touch !</h3>

                                <form id="create-course-form" method='post' onSubmit={handleSubmit}>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.User className="size-4 absolute top-3 start-4"></Icon.User>
                                                    <input onChange={(e) => {setName(e.target.value)}} name="name" id="name" type="text" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Name :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.Mail className="size-4 absolute top-3 start-4"></Icon.Mail>
                                                    <input onChange={(e) => {setEmail(e.target.value)}} name="email" id="email" type="email" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Email :" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.Book className="size-4 absolute top-3 start-4"></Icon.Book>
                                                    <input onChange={(e) => {setQuestion(e.target.value)}} name="subject" id="subject" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Subject :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.MessageCircle className="size-4 absolute top-3 start-4"></Icon.MessageCircle>
                                                    <textarea onChange={(e) => {setComment(e.target.value)}} name="comments" id="comments" className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Message :"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type='submit' className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full">Submit</button>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
          </div>     





      <Footer />
      <CookieModal />
    </>
  )
}
