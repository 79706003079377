import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';

import Startup_SVG from '../../assets/images/illustrator/Startup_SVG.svg';
import SEO_SVG from '../../assets/images/illustrator/SEO_SVG.svg';
import Encryption from '../../assets/images/about/encryption.png';
import classic03 from '../../assets/images/saas/classic03.png';
import imageP from '../../assets/images/hosting/2.png';

import AppIcon from '../../assets/images/client/appIcon.png';
import encryptionIcon from '../../assets/images/client/encryptionIcon.png';
import Mobile_notification_SVG from '../../assets/images/illustrator/Mobile_notification_SVG.svg';
import Blog1 from '../../assets/images/blog/bg1.jpg';
import LaptopScreen from '../../assets/images/client/laptopScreen.png';

import TinySlider from 'tiny-slider-react';
import '../../../node_modules/tiny-slider/dist/tiny-slider.css'

import Navbar from '../../component/Navbar/navbar';
import Footer from '../../component/Footer/footer';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';
import GetInTuch from '../../component/getInTuch';
import BlogUserDetail from '../../component/blogUserDetail';
import { blogData } from '../../data/data';

import * as Icon from 'react-feather';
import {FaRegEnvelope, BiBookAlt, BsCheckCircle, MdKeyboardArrowRight} from '../../assets/icons/icons'
import { feedback } from '../../data/data';
import { corporateAbout2, corporateServices2 } from '../../data/dataTwo';


import { about } from '../../data/dataTwo';
import {MdOutlineEventNote} from '../../assets/icons/icons'


import { startupAbout, startupServices } from '../../data/dataTwo';
import { accordionData } from '../../data/dataTwo';


const settings = {
  container: '.tiny-two-item',
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  controlsText: ['<i className="mdi mdi-chevron-left"></i>', '<i className="mdi mdi-chevron-right"></i>'],
  nav: false,
  speed: 400,
  gutter: 0,
  responsive: {
    768: {
      items: 2
    },
  },
}
export default function Product() {

  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);

  let toggleAccordion = (index) => {
      if (activeIndex === index) {
          setActiveIndex(0);
      } else {
          setActiveIndex(index);
      }
  }
  
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
    
  }, []);



  return (
    <div >

      <Navbar />

      <section className="relative table w-full py-36 pb-24 ">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="md:col-span-7">
              <div className="me-6">
              {/* <h6 class="mb-4 text-base font-medium text-indigo-600">Mobile + TV Apps</h6> */}
                <h4 className="font-semibold lg:leading-normal heading leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">teachable:u courses<span className="text-indigo-600"> </span></h4>
                <p className="text-slate-400 text-lg max-w-xl">Go from learning to doing with our library of creator-specific mini courses. We’ll meet you where you are with the strategies you need to build and scale a thriving online knowledge business.</p>

                <div className="mt-6">
                  <Link to="/contact-one" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaRegEnvelope  className="me-2 text-sm "/> Get Started</Link>
                  {/* <Link to="/documentation" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md mt-2"> Book Demo</Link> */}
                </div>
              </div>
            </div>

            <div className="md:col-span-5">
              <img src='images/resources_banner.svg' alt="" />
            </div>
          </div>
        </div>
      </section>


      <section className="pt-12 w-full  border mb-12 mt-5">
        <div className="absolute inset-0 bg-[url('../../assets/images/insurance/cta.png')] bg-bottom bg-no-repeat bg-cover"></div>
        <div class="container relative ">
                <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                    <div class="lg:col-span-12 md:col-span-6"><div>
                        {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">Blogs</h6> */}
                    <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0 ">Featured t:u courses</h3>
                    <p class="text-slate-400 max-w-xl mt-2">There’s no all-in-one formula for success as a creator. That’s why we let you choose how to build your business.</p></div>
                        <div class="grid lg:grid-cols-3 grid-cols-1 gap-[30px] py-16">
                            <div class="blog relative rounded-md shadow bg-white dark:shadow-gray-800 overflow-hidden">
                                {/* <img src={Blog}/> */}
                                <img src='images/resource_img1.jpg' alt="" className=''/>
        
                                <div class="content p-6">
                                    <a href="blog-detail.html" class="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out ">Design your apps in your own way</a>
                                    <p class="text-slate-400 mt-3 ">The phrasal sequence of the is now so that many campaign and benefit</p>
                                    
                                    <div class="mt-4">
                                        <a href="blog-detail.html" class="relative  inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i class="uil uil-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
        
                            <div class="blog relative rounded-md shadow bg-white dark:shadow-gray-800 overflow-hidden">
                              <img src='images/resource_img1.jpg' alt="" className=''/>
        
                                <div class="content p-6">
                                    <a href="blog-detail.html" class="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out ">How apps is changing the IT world</a>
                                    <p class="text-slate-400 mt-3 ">The phrasal sequence of the is now so that many campaign and benefit</p>
                                    
                                    <div class="mt-4">
                                        <a href="blog-detail.html" class="relative  inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i class="uil uil-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
        
                            <div class="blog relative rounded-md shadow bg-white dark:shadow-gray-800 overflow-hidden">
                              <img src='images/resource_img1.jpg' alt="" className=''/>
                                <div class="content p-6">
                                    <a href="blog-detail.html" class="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out ">Smartest Applications for Business</a>
                                    <p class="text-slate-400 mt-3 ">The phrasal sequence of the is now so that many campaign and benefit</p>
                                    
                                    <div class="mt-4">
                                        <a href="blog-detail.html" class="relative  inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i class="uil uil-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </section>

    <div className="container relative md:mt-24 mt-16 pb-9">
        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="lg:col-span-6 md:order-2 order-1">
                <div className="lg:ms-8">
                    <img src={imageP} alt="" />
                </div>
            </div>
            <div className="lg:col-span-6 md:order-1 order-2">
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Why Partner With Learnyst?</h3>
                <p className="text-slate-400 max-w-xl">We have helped 2000+ educators launch their own profitable & secure online academy from their own brand.</p>

                <ul className="list-none text-slate-400 mt-4  space-x-1">
                    <li className="mb-1 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Digital Marketing Solutions for Tomorrow</li>
                    <li className="mb-1 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Our Talented & Experienced Marketing Agency</li>
                    <li className="mb-1 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Create your own skin to match your brand</li>
                </ul>

                <div className="mt-4">
                    <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl ms-1"/> </Link>
                </div>
            </div>
        </div>
    </div>

    <section className="pb-12 mt-9 w-full table relative bg-indigo-600 mb-12">
        <div className="absolute inset-0 bg-[url('../../assets/images/insurance/cta.png')] bg-bottom bg-no-repeat bg-cover"></div>
        <div className="container relative md:mt-24 mt-16 ">
          <div className="grid grid-cols-1 text-center">
             <h6 className="mb-4 md:text-4xl text-3xl lg:leading-normal leading-normal text-white font-semibold">Contact Us</h6> 
            <h3 className="mb-6 md:text-3xl text-white text-2xl md:leading-normal leading-normal font-semibold">Have Question ? Get in touch!</h3>

            <p className="text-slate-400 text-white/80 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>

            <div className="mt-6">
                <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Book Demo</Link>
            </div>
          </div>
        </div>
    </section>
        

       

      <Footer />

      <CookieModal />

     
    </div>
  )
}
