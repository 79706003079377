import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import about2 from '../../assets/images/company/about2.png';
import cta_bg from '../../assets/images/cta-bg.jpg';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
import Blog2 from '../../component/blog2';
import imageP from '../../assets/images/portfolio/01.jpg';
import image1 from '../../assets/images/portfolio/02.jpg';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';
import KeyFeature from '../../component/keyFeature';
import KeyFeatureTwo from '../../component/keyFeatureTwo';
import laptop from '../../assets/images/laptop.png';


import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";

import * as Icon from 'react-feather';
import { FaArrowRight, MdKeyboardArrowRight } from '../../assets/icons/icons'


import { featureData } from '../../data/dataFour';
import { accordionData } from '../../data/dataTwo';
import { TypeAnimation } from 'react-type-animation';


export default function IndexLandingFour() {
  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);


  let toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  }
  return (
    <>
              <Navbar navClass="nav-sticky" />
        <section className="relative table w-full pt-24 pb-24 bg-gray-50 dark:bg-slate-800  overflow-hidden " id="home">
          <div className="container relative">
            <div className="grid lg:grid-cols-12 md:grid-cols-2 mt-10 items-center gap-[30px]">
              <div className="lg:col-span-6 mt-9">
                {/* <span className="bg-indigo-600/5 text-indigo-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">Form Landing Page</span> */}

                {/* <h4 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mt-2 mb-5">Cutting-Edge POS <br/>Software for, </h4> */}
                <h1 className="font-bold heading lg:leading-normal leading-normal text-5xl lg:text-5xl mb-5">Your Complete POS Solution for Seamless Operations </h1>

                <p className="text-slate-400 text-lg max-w-xl text-justify">Empower your business with seamless, efficient, and reliable point-of-sale solutions. From small retailers to bustling restaurants, our POS system adapts to your needs.</p>

                <div className="relative mt-10">
                    <Link to="/get-started"  className="py-2 px-5  inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link>
                  {/* <Link className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full me-5 ms-4"> Support Training</Link> */}

                </div>
              </div>
              <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} />

              <div className="lg:col-span-6">
                <div className="dark:bg-slate-900 rounded-xl  dark:shadow-gray-800  lg:ms-10 z-10 relative">
                  {/* <h4 className="mb-5 text-2xl font-semibold">Just Trial Form</h4> */}
                  <div className="relative after:content-[''] after:absolute after:md:bottom-12 after:-bottom-20 lg:after:-start-0 md:after:-start-20 after:-start-24
                    after:bg-indigo-600 after:shadow-2xl after:shadow-indigo-600/40 after:-z-1 ltr:after:rotate-[130deg] rtl:after:-rotate-[130deg] after:w-[75rem] after:md:h-[55rem] after:h-[30rem] after:rounded-[20rem]">
                        <img src='images/pos/pos8.jpg' className='p-3 border shadow radius-4'></img>
                        </div>

                </div>
              </div>
            </div>
          </div>
        </section>

      <div className="container relative mt-24">
        <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
          <div className="lg:col-span-12 md:col-span-12 md:text-start text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">Streamline Operations. Boost Sales. Delight Customers.</h3>
            <p className="text-slate-400  mt-2 width80 m-auto text-center">Experience the ultimate in transaction management with our innovative POS solutions. Easy to use, <br/> powerful, and designed to grow with your business.</p>
          </div>
        </div>


        <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                  <img src='images/pos/pos6.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-indigo-600 transition-all duration-500 ease-in-out">Improved Efficiency and Speed</Link></h5>
                <span className="text-slate-400 text-justify">POS systems streamline the checkout process, reducing wait times and improving customer satisfaction.</span>
              </div>
            </div>
          </div>

          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                  <img src='images/pos/pos3.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Enhanced Customer Experience</Link></h5>
                <span className="text-slate-400 text-justify">By storing customer data, it enables personalized services such as customized receipts, enhancing overall customer satisfaction.</span>
              </div>
            </div>
          </div>

          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                  <img src='images/pos/pos7.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Reduced Errors</Link></h5>
                <span className="text-slate-400 text-justify">Automated data entry and processing reduce the likelihood of human errors, ensuring more accurate transaction records.</span>
              </div>
            </div>
          </div>

          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                  <img src='images/pos/pos8.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Scalability</Link></h5>
                <span className="text-slate-400 text-justify">As businesses grow, our POS easily scale to accommodate additional stores, making them a flexible solution for expanding businesses.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container relative mt-24">
        <div className="grid grid-cols-1 pb-8 text-center">
          {/* <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Features</h6> */}
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Features</h3>

          <p className="text-slate-400  mx-auto">POS includes a range of features to facilitate sales transactions, inventory management, and customer service.</p>
        </div>

        <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/pos/user-interface.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">User Interface</Link>
              {/* <p className="text-slate-400 mt-3">Streamline your operations and drive business growth.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/pos/cyber-security.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Security</Link>
              {/* <p className="text-slate-400 mt-3">Track stock levels and manage suppliers seamlessly.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/pos/mobility.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Mobility</Link>
              {/* <p className="text-slate-400 mt-3">Monitor data and payroll of  employees</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/pos/sales.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Sales Processing</Link>
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/pos/report.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Reporting & Analytics</Link>
              {/* <p className="text-slate-400 mt-3">Ensure secure payment processing with support.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/pos/integration.png'className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Smooth Integration </Link>
              {/* <p className="text-slate-400 mt-3">Monitor sales, inventory, and staff across all your locations.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>
        </div>
      </div>

      <section className="relative md:py-24 py-16 ">
        <div className="container relative">
          <div className='text-center pb-16'>
            {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
            <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Benefits of POS </h3>
            <p class="text-slate-400  mt-2 width80 m-auto">Our software revolutionizes the way businesses manage transactions, streamline operations, and enhance customer experiences.</p>
          </div>
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Robust Reporting Tools</h3>
                <ul className='list-none text-slate-400 mt-4'>
                  <li class="mb-1 flex items-center ms-0"><b>Sales Reports</b>:</li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>   Analyze daily, weekly, and monthly sales to identify trends.
                  </li>

                  <li class="mb-1 flex items-center ms-0 mt-4"><b>Inventory Reports</b>: </li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>  Monitor stock levels, identify slow-moving items, and optimize ordering.
                  </li>

                  <li class="mb-1 flex items-center ms-0 mt-4"><b>Customer Insights</b>: </li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg> : Track purchasing habits and tailor marketing efforts accordingly
                  </li>
                </ul>
                
              </div>
            </div>
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src='images/pos/pos9.jpg' alt="" />
            </div>
          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/pos/pos12.jpg" alt="" />
            </div>
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Advanced Inventory Management</h3>
                <ul className='list-none text-slate-400 mt-4'>
                  <li class="mb-1 flex items-center ms-0"><b>Real-Time Tracking</b>:</li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>   Always know what's in stock and what's running low.
                  </li>

                  <li class="mb-1 flex items-center ms-0 mt-4"><b>Supplier Management</b>: </li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>  Simplify your supply chain with automated ordering and tracking.
                  </li>

                  <li class="mb-1 flex items-center ms-0 mt-4"><b>Product Variants</b>: </li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg> Manage different sizes, colors, and styles with ease.
                  </li>
                </ul>

              </div>
            </div>

          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Multi-Platform Access</h3>
                <ul className='list-none text-slate-400 mt-4'>
                  <li class="mb-1 flex items-center ms-0"><b>Mobile POS</b>:</li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>  Take orders and process payments from anywhere in your store.
                  </li>

                  <li class="mb-1 flex items-center ms-0 mt-4"><b>Desktop Integration</b>: </li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>  Seamlessly integrate with your existing desktop setup.
                  </li>

                  <li class="mb-1 flex items-center ms-0 mt-4"><b>Cloud-Based</b>: </li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg> : Access your data anytime, anywhere with our secure cloud solution.
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/pos/pos13.jpg" alt="" />
            </div>


          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/pos/pos11.jpg" alt="" />
            </div>
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Secure and Reliable</h3>
                <ul className='list-none text-slate-400 mt-4'>
                  <li class="mb-1 flex items-center ms-0"><b>Data Encryption</b>:</li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>  Protect sensitive information with state-of-the-art encryption.
                  </li>

                  <li class="mb-1 flex items-center ms-0 mt-4"><b>Fraud Prevention</b>: </li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>  Advanced tools to detect and prevent fraudulent activities.
                  </li>

                  <li class="mb-1 flex items-center ms-0 mt-4"><b>PCI Compliance</b>: </li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg> Fully compliant with payment card industry standards.
                  </li>
                </ul>
              </div>
            </div>
          </div>


        </div>
      </section>




      <section className='py-16 mt-9 w-full table relative bg-indigo-600 mb-12'>
        <div className='container'>
          <h3 class="mb-4 md:text-4xl text-3xl lg:leading-normal leading-normal text-white font-semibold text-center">Ready to Get Started?</h3>
          <p class="text-white/80 max-w-xl mx-auto lg:ms-auto  text-center">Transform your business with the power of our POS software. Contact us today and discover how we can help you achieve greater efficiency and profitability.</p>
          <div class="mt-2 d-flex justify-content-center" style={{ justifyContent: "center", display: 'flex' }}>
            <div className="mt-6">
                <Link to="/contact-one" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Contact Us</Link>
              {/* <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Start Free</Link> */}
            </div>
          </div>
        </div>
      </section>



      <Footer />
      <CookieModal />
    </>
  )
}
