import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import about2 from '../../assets/images/company/about2.png';
import cta_bg from '../../assets/images/cta-bg.jpg';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
import Blog2 from '../../component/blog2';
import imageP from '../../assets/images/portfolio/01.jpg';
import image1 from '../../assets/images/portfolio/02.jpg';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';
import KeyFeature from '../../component/keyFeature';
import KeyFeatureTwo from '../../component/keyFeatureTwo';
import app from '../../assets/images/app/app.png';
import playstore from '../../assets/images/app/playstore.png';
import hero from '../../assets/images/course/hero.png';

import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";

import * as Icon from 'react-feather';
import { FaArrowRight, MdKeyboardArrowRight } from '../../assets/icons/icons'


import { featureData } from '../../data/dataFour';
import { accordionData } from '../../data/dataTwo';
import { TypeAnimation } from 'react-type-animation';


export default function IndexLandingFour() {
  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);


  let toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  }
  return (
    <>
      <Navbar />
            <section className="relative table w-full py-36 lg:py-44 bg-indigo-600/5 overflow-hidden">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px] relative">
                        <div className="md:col-span-7">
                            <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">Effortless Setup,  <br/> <span className="text-indigo-600">Seamless</span> Integration</h4>
                            <p className="text-slate-400 text-lg max-w-xl">At Flavour, we understand the importance of efficiency in your business operations. That's why we've designed our POS software with an easy installation process, ensuring you can get up and running in no time.  </p>
                            <div className="mt-6">
                                <Link to="/get-started"  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link>
                            </div>
                        </div>

                        <div className="md:col-span-5">
                            <div className="relative">
                                <img src='images/installation/banner.png' alt="" />
                            </div>
                        </div>

                        <div className="overflow-hidden after:content-[''] after:absolute after:size-32 after:bg-red-600/5 after:top-10 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]"></div>
                        <div className="overflow-hidden after:content-[''] after:absolute after:size-[512px] after:bg-indigo-600/5 after:top-0 after:-end-5 after:-z-1 after:rounded-full"></div>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px] relative">
                        <div className="lg:col-span-12 md:col-span-12">
                            <div className="grid grid-cols-1 pb-8">
                                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">Install and Go in a Few Clicks</h3>

                                <p className="text-slate-400  mt-2 width80 m-auto text-center">Our POS software's easy installation feature streamlines your setup process, <br/>ensuring a hassle-free experience.</p>
                            </div>

                            <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                                <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                                    {/* <Icon.Users className="size-12 stroke-1 text-indigo-600"></Icon.Users> */}
                                    <img src="images/icons/save-time.png" alt="" class="appIcon icon-orange width18"></img>
                                    <div className="content mt-4">
                                        <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Save Time</Link>
                                        <p className="text-slate-400 mt-3 text-justify">Spend less time setting up and more time focusing on your business</p>

                                       
                                    </div>
                                </div>

                                <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                                    {/* <Icon.Users className="size-12 stroke-1 text-indigo-600"></Icon.Users> */}
                                    <img src="images/icons/technical-skills.png" alt="" class="appIcon icon-orange width18"></img>


                                    <div className="content mt-4">
                                        <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">No Technical Expertise Required</Link>
                                        <p className="text-slate-400 mt-3 text-justify">Our user-friendly interface ensures effortlessly install and configure the software.</p>

                                       
                                    </div>
                                </div>

                                <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                                    {/* <Icon.Users className="size-12 stroke-1 text-indigo-600"></Icon.Users> */}
                                    <img src="images/icons/down-time.png" alt="" class="appIcon icon-orange width15"></img>


                                    <div className="content mt-4">
                                        <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Reduce Downtime</Link>
                                        <p className="text-slate-400 mt-3 text-justify">Minimize downtime associated with software setup and updates. </p>

                                       
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="overflow-hidden after:content-[''] after:absolute after:h-40 after:w-40 after:bg-red-600/5 after:top-2/4 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]"></div>
                        <div className="overflow-hidden after:content-[''] after:absolute after:size-[512px] after:bg-indigo-600/5 after:top-1/4 after:end-0 after:-z-1 after:rounded-full"></div>
                    </div>
                </div>
            </section>
             
            
            <section className="relative pb-16 ">
                <div className="container relative">
                    <div className='text-center pb-16'>
                        {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
                        <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Why Choose Us?</h3>
                        <p class="text-slate-400  mt-2 width80 m-auto">With our user-friendly interface and step-by-step guidance,<br/> you can have your system up and running in no time.</p>
                    </div>
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">

                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Streamlined Setup</h3>
                                <p className="text-slate-400 mt-3 width80 text-justify">Our POS software is designed with simplicity in mind. Say goodbye to lengthy installation procedures and complex configurations. With just a few clicks, you'll have our software installed and ready to use</p>
                            </div>
                        </div>
                        <div className="lg:col-span-5 p-2 border shadow radius-4">
                            <img src='images/installation/img4.jpg' alt="" />
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
                        <div className="lg:col-span-5 p-2 border shadow radius-4">
                            <img src="images/installation/img5.jpg" alt="" />
                        </div>
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">

                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Step-by-Step Guidance</h3>
                                <p className="text-slate-400 mt-3 width80 text-justify">Don't worry if you're not a tech expert. Our intuitive setup manual will guide you through the installation process, providing clear instructions at every step. Whether you're a seasoned professional or new to POS systems, you'll find our setup process a breeze. </p>

                            </div>
                        </div>

                    </div>

                    <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">

                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Compatibility Across Devices</h3>
                                <p className="text-slate-400 mt-3 width80 text-justify">Our POS software is compatible with a wide range of hardware devices, including windows, smartphones, and traditional POS terminals. No matter what devices you prefer to use in your business, you can trust that our software will seamlessly integrate with them.</p>

                            </div>
                        </div>
                        <div className="lg:col-span-5 p-2 border shadow radius-4">
                            <img src="images/installation/img9.jpg" alt="" />
                        </div>


                    </div>

                    <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
                        <div className="lg:col-span-5 p-2 border shadow radius-4">
                            <img src="images/installation/img8.jpg" alt="" />
                        </div>
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">

                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Remote Assistance</h3>
                                <p className="text-slate-400 mt-3">If you encounter any difficulties during the installation process, our dedicated support team is here to help. With remote assistance capabilities, we can quickly diagnose and resolve any issues, ensuring minimal disruption to your business operations.</p>

                            </div>
                        </div>
                    </div>


                </div>
            </section>

    {/* <section className="relative md:py-24 py-16 bg-gray-50">
        <div className="container relative">
            <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
                <div className="lg:col-span-8 md:col-span-6 md:text-start text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Explore Latest Works</h3>
                    <p className="text-slate-400 max-w-xl">Start working with Techwind that can provide everything you need to generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                <div className=" picture-item rounded-md group  rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
                    <div className="">
                    <div className="relative">
                        <div className=" dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                            <img src='images/installation/img3.jpg' className="rounded-t-md shadow " alt="" />
                        </div>
                    </div>

                    
                    </div>
                </div>

                <div className=" picture-item rounded-md group  rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
                    <div className="">
                    <div className="relative">
                        <div className=" dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                        <img src='images/installation/img2.jpg' className="rounded-t-md shadow " alt="" />
                        </div>
                    </div>
                    </div>
                </div>

                <div className=" picture-item rounded-md group  rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
                    <div className="">
                    <div className="relative">
                        <div className=" dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                        <img src='images/installation/img1.jpg' className="rounded-t-md shadow " alt="" />
                        </div>
                    </div>

                    
                    </div>
                </div>
                </div>
        </div>
    </section> */}
        
      <section className='py-16 mt-7 w-full table relative bg-indigo-600 mb-16'>
        <div className='container'>
          <h3 class="mb-4 md:text-4xl text-3xl lg:leading-normal leading-normal text-white font-semibold text-center">Ready to experience the ease of installation?</h3>
          <p class="text-white/80 max-w-xl mx-auto lg:ms-auto  text-center">Contact us for a free demo today and see for yourself how our intuitive setup process can transform your business.</p>
          <div class="mt-2 d-flex justify-content-center" style={{ justifyContent: "center", display: 'flex' }}>
            <div className="mt-6">
              <Link to="/contact-one" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Contact Us</Link>
              {/* <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Start Free</Link> */}
            </div>
          </div>
        </div>
      </section>



      <Footer />
      <CookieModal />
    </>
  )
}
