import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';

import Startup_SVG from '../../assets/images/illustrator/Startup_SVG.svg';
import SEO_SVG from '../../assets/images/illustrator/SEO_SVG.svg';
import Encryption from '../../assets/images/about/encryption.png';
import classic03 from '../../assets/images/saas/classic03.png';

import AppIcon from '../../assets/images/client/appIcon.png';
import encryptionIcon from '../../assets/images/client/encryptionIcon.png';
import Mobile_notification_SVG from '../../assets/images/illustrator/Mobile_notification_SVG.svg';
import Blog1 from '../../assets/images/blog/bg1.jpg';
import LaptopScreen from '../../assets/images/client/laptopScreen.png';
import AboutOne from '../../component/aboutOne';

import TinySlider from 'tiny-slider-react';
import '../../../node_modules/tiny-slider/dist/tiny-slider.css'

import Navbar from '../../component/Navbar/navbar';
import Footer from '../../component/Footer/footer';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';
import GetInTuch from '../../component/getInTuch';
import BlogUserDetail from '../../component/blogUserDetail';
import { blogData } from '../../data/data';

import * as Icon from 'react-feather';
import {FaRegEnvelope, BiBookAlt, BsCheckCircle, MdKeyboardArrowRight} from '../../assets/icons/icons'
import { feedback } from '../../data/data';
import { corporateAbout2, corporateServices2 } from '../../data/dataTwo';


import { about } from '../../data/dataTwo';
import {MdOutlineEventNote} from '../../assets/icons/icons'


import { startupAbout, startupServices } from '../../data/dataTwo';
import { accordionData } from '../../data/dataTwo';


const settings = {
  container: '.tiny-two-item',
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  controlsText: ['<i className="mdi mdi-chevron-left"></i>', '<i className="mdi mdi-chevron-right"></i>'],
  nav: false,
  speed: 400,
  gutter: 0,
  responsive: {
    768: {
      items: 2
    },
  },
}
export default function Product() {

  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);

  let toggleAccordion = (index) => {
      if (activeIndex === index) {
          setActiveIndex(0);
      } else {
          setActiveIndex(index);
      }
  }
  
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
    
  }, []);



  return (
    <div >

      <Navbar />

      <section className="relative table w-full py-36 pb-24 ">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="md:col-span-6">
              <div className="me-6">
              {/* <h6 class="mb-4 text-base font-medium text-indigo-600">Mobile + TV Apps</h6> */}
                <h4 className="font-semibold lg:leading-normal heading leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">Live Streaming Classroom   <span className="text-indigo-600">Experience</span> </h4>
                <p className="text-slate-400 text-lg max-w-xl text-justify">Experience the future of learning from anywhere with our innovative platform where education comes alive through live streaming from classrooms!</p>

                <div className="mt-6">
                  <Link to="" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaRegEnvelope  className="me-2 text-sm "/> Get Started</Link>
                  {/* <Link to="/documentation" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md mt-2"> Book Demo</Link> */}
                </div>
              </div>
            </div>

            <div className="md:col-span-6">
              <img src='images/teaching_banner.png' alt="" />
            </div>
          </div>
        </div>
      </section>

        <div className="container relative mt-9">
            <div className="grid grid-cols-1 pb-8 text-center">
                {/* <h6 class="mb-4 text-base font-medium text-indigo-600">Why Choose Us?</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Why Choose Us?</h3>
                <p className="text-slate-400 max-w-xl mx-auto">Choosing us for live streaming from your classroom ensures a seamless and enriching experience for both educators and students. Our platform is user-friendly, allowing easy access for viewers on various devices. </p>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
            <div className="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                <img src='images/live_steam.png' className="rounded-lg -md dark:shadow-gray-800" alt="" />
                <div className="overflow-hidden absolute lg:size-[400px] size-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
            </div>

            <div className="lg:ms-4 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                <h4 className="mb-4 text-2xl leading-normal font-medium">Join Real-Time Learning from Anywhere!</h4>
                <p className="text-slate-400">Access real-time lessons, interact with educators, and immerse yourself in dynamic learning experiences from anywhere in the world.</p>
                <ul className="list-none text-slate-400 mt-4">
                    <li className="mb-2 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/>Immerse yourself in real-time lessons from our expert educators. Join engaging sessions that bring the classroom to your screen.</li>
                    <li className="mb-2 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/>Participate, ask questions, and collaborate with fellow learners through our live streaming platform.</li>
                    <li className="mb-2 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Our educators covers a range of subjects for various grade levels. Discover classes tailored to your interests.</li>
                    <li className="mb-2 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/> Can't make a live session? No worries! Access recordings of past classes at your convenience.</li>
                    <li className="mb-2 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2"/>Stay updated with upcoming live sessions and special events. Be part of a vibrant learning community.</li>
                </ul>

                <div className="mt-4">
                    {/* <Link to="" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl ms-1"/></Link> */}
                </div>
            </div>
        </div>
        </div>



        <div class="container relative mt-36">
          <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
              <div class="lg:col-span-12 md:col-span-6">
              <div>
                {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MARKETING PRODUCTS</h6> */}
                <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Live Classroom Streaming for Dynamic Learning!</h3>
                  <p class="text-slate-400 max-w-xl mt-2">Live streaming empowers educators to extend their reach beyond the physical classroom, fostering interactive and inclusive learning environments.</p>
                </div>
                  <div class="grid lg:grid-cols-2 grid-cols-1 gap-[30px] py-16">
                      <div class="blog relative text-center rounded-md shadow  overflow-hidden  pt-5">
                          {/* <img src={Blog}/> */}
                          <img src='/images/Real-TimeLearning.png' alt="" className=' width60 m-auto'/>
  
                          <div class="content p-6">
                              <Link href="" class="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Real-Time Learning</Link>
                              <p class="text-slate-400 mt-3">: Join classes as they happen, with live interaction and <br/>engagement with instructors.</p>
                              
                              {/* <div class="mt-4">
                                  <Link href="" class="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out text-center">Read More <i class="uil uil-arrow-right"></i></Link>
                              </div> */}
                          </div>
                      </div>
  
                      <div class="blog relative text-center rounded-md shadow dark:shadow-gray-800 overflow-hidden pt-5">
                      <img src='/images/FlexibleAccess.png' alt="" className='width60 m-auto'/>
                          <div class="content p-6">
                              <Link href="" class="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Flexible Access</Link>
                              <p class="text-slate-400 mt-3 text-center"> Attend classes remotely from your home, office, or <br/>on-the-go using any device.</p>
                              
                              {/* <div class="mt-4">
                                  <Link href="" class="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out text-center">Read More <i class="uil uil-arrow-right"></i></Link>
                              </div> */}
                          </div>
                      </div>
  
                      <div class="blog relative text-center rounded-md shadow dark:shadow-gray-800 overflow-hidden  pt-5">
                        <img src='/images/InteractiveSessions.png' alt="" className='width60 m-auto'/>

                          <div class="content p-6 text-center">
                              <Link href="" class="title h5 text-lg font-medium text-center hover:text-indigo-600 duration-500 ease-in-out m-auto text-center justify-content-center d-flex">Interactive Sessions</Link>
                              <p class="text-slate-400 mt-3 m-auto text-center">Participate in discussions, Q&A, and activities<br/> alongside fellow students.</p>
                              
                              {/* <div class="mt-4">
                                  <Link href="" class="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out text-center">Read More <i class="uil uil-arrow-right"></i></Link>
                              </div> */}
                          </div>
                      </div>

                      <div class="blog relative text-center rounded-md shadow dark:shadow-gray-800 overflow-hidden pt-5">
                        <img src='/images/RecordedSessions2.png' alt="" className='width60 m-auto'/>

                          <div class="content p-6">
                              <Link href="" class="title h5 text-lg text-center font-medium justify-content-center hover:text-indigo-600 duration-500 ease-in-out m-auto text-center d-flex">Recorded Sessions</Link>
                              <p class="text-slate-400 mt-3 m-auto text-center">Missed a class? No worries. Rewatch sessions at your convenience<br/> with our archive feature.</p>
                              
                              {/* <div class="mt-4">
                                  <Link href="" class="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out text-center">Read More <i class="uil uil-arrow-right"></i></Link>
                              </div> */}
                          </div>
                      </div>

                      
                  </div>
              </div>
          </div>
      </div>


      <div className="container relative mt-16">
        <div className='display-flex justify-content-center'>
        <h3 className="mb-4 md:text-3xl  md:leading-normal text-center text-2xl leading-normal font-semibold">Engage, Educate, Stream Transforming Classrooms Live!</h3>
        <p class="text-slate-400   pb-12 text-center">Tune in, interact, and be part of our educational community<br/> as we explore the world of knowledge together!</p>

          <img src='images/teaching_img1.png' alt="" className='m-auto  width60'/>
        </div>
            <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('../../assets/images/map.png')] bg-no-repeat bg-center bg-cover"></div>
      </div>
        

           {/* faqs Start here */}
      <div className="container relative pt-5 mt-9">
        <div className="grid grid-cols-1 pb-5 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Frequently Asked Questions</h3>

            <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>
        </div>

        <div className="relative grid md:grid-cols-12 grid-cols-1 items-center  gap-[30px]">
            <div className="md:col-span-6">
                <div className="relative">
                    <div className="relative rounded-xl overflow-hidden ">
                        {/* <div className="w-full py-72 bg-slate-400 bg-no-repeat bg-top bg-cover jarallax" data-jarallax data-speed="0.5"> */}
                          <img src='images/13330330_Feb-Business_9.svg'alt="" className=''/>
                        {/* </div> */}
                    </div>
                </div>
            </div>

            <div className="md:col-span-6">
                <div id="accordion-collapse">
                    {accordionData.slice(0,4).map((item, index) => (
                        <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                            <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                <button type="button" onClick={() => toggleAccordion(index)} className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                    <span>{item.title}</span>
                                    <svg data-accordion-icon className={`${activeIndex === index ? "rotate-180" : "rotate-270" } size-4 shrink-01`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </button>
                            </h2>
                            {activeIndex === index && (
                                <div>
                                    <div className="p-5">
                                        <p className="text-slate-400 dark:text-gray-400">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                                    </div>
                                </div>
                            )}
                        </div>

                    ))}
                </div>
            </div>
        </div>
      </div>

      
    

      <Footer />

      <CookieModal />

     
    </div>
  )
}
