import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import about2 from '../../assets/images/company/about2.png';
import cta_bg from '../../assets/images/cta-bg.jpg';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
import Blog2 from '../../component/blog2';
import imageP from '../../assets/images/portfolio/01.jpg';
import image1 from '../../assets/images/portfolio/02.jpg';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';
import KeyFeature from '../../component/keyFeature';
import KeyFeatureTwo from '../../component/keyFeatureTwo';


import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";

import * as Icon from 'react-feather';
import { FaArrowRight, MdKeyboardArrowRight } from '../../assets/icons/icons'


import { featureData } from '../../data/dataFour';
import { accordionData } from '../../data/dataTwo';
import { TypeAnimation } from 'react-type-animation';


export default function IndexLandingFour() {
  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);


  let toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  }
  return (
    <>
      <Navbar />
      <section className="relative table w-full pt-24 pb-36 bg-gray-50 dark:bg-slate-800 overflow-hidden" id="home">
        <div className="container relative">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 mt-10 items-center gap-[30px]">
            <div className="lg:col-span-6 mt-9">
              {/* <span className="bg-indigo-600/5 text-indigo-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">Form Landing Page</span> */}

              {/* <h4 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mt-2 mb-5">Cutting-Edge POS <br/>Software for, </h4> */}
              <h1 className="font-bold heading lg:leading-normal leading-normal text-5xl lg:text-5xl mb-5">Seamless Reporting for Smarter Decisions</h1>

              <p className="text-slate-400 text-lg max-w-xl text-justify">Our software is designed to simplify and enhance your reporting process, allowing you to generate insightful reports efficiently and accurately.</p>

              <div className="relative mt-10">
              <Link to="/get-started"  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link>

                {/* <Link className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full me-5 ms-4"> Support Training</Link> */}

              </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} />

            <div className="lg:col-span-6">
              <div className="dark:bg-slate-900 rounded-xl  dark:shadow-gray-800 md:p-8 p-6 lg:ms-10 z-10 relative">
                {/* <h4 className="mb-5 text-2xl font-semibold">Just Trial Form</h4> */}

                  <div className="relative after:content-[''] after:absolute after:md:bottom-12 after:-bottom-20 lg:after:-start-0 md:after:-start-20 after:-start-24
                    after:bg-indigo-600 after:shadow-2xl after:shadow-indigo-600/40 after:-z-1 ltr:after:rotate-[130deg] rtl:after:-rotate-[130deg] after:w-[75rem] after:md:h-[55rem] after:h-[30rem] after:rounded-[20rem]">
                        <img src='images/reporting/reporting9.jpg' className='p-3 border shadow radius-4'></img>
                    </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container relative mt-24">
        <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
          <div className="lg:col-span-12 md:col-span-12 md:text-start text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">Effortless Reporting, Powerful Results</h3>
            <p className="text-slate-400  mt-2 width80 m-auto text-center">Designed to streamline data analysis, it delivers accurate, real-time insights that empower informed decision-making.</p>
          </div>
        </div>


        <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                  <img src='images/reporting/reporting8.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl">Accuracy and Reliability</h5>
                <span className="text-slate-400 text-justify">Deliver precise and reliable data, ensuring that you make informed decisions.</span>
              </div>
            </div>
          </div>

          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                  <img src='images/reporting/reporting7.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl">User-Friendly Interface</h5>
                <span className="text-slate-400 text-justify">We prioritize user experience, offering an easy- to- navigate interface.</span>
              </div>
            </div>
          </div>

          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                  <img src='images/reporting/reporting6.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl">Scalable Solutions</h5>
                <span className="text-slate-400 text-justify">As your business grows, it adapts to handle increased data volumes.</span>
              </div>
            </div>
          </div>

          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                  <img src='images/reporting/reporting5.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl">Visualizations</h5>
                <span className="text-slate-400 text-justify">Transform complex data into easy-to-understand charts, graphs, etc.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container relative mt-24">
        <div className="grid grid-cols-1 pb-8 text-center">
          {/* <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Features</h6> */}
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Explore Our Key Features </h3>

          <p className="text-slate-400  mx-auto">Our reporting software boasts a range of powerful features designed to enhance data analysis and decision-making.</p>
        </div>

        <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/reporting/builder.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Customized report builder</Link>
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/reporting/dashboard.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Advanced dashboard</Link>
              {/* <p className="text-slate-400 mt-3">Track stock levels and manage suppliers seamlessly.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/reporting/graphical-data-representation.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Data visualization tools (charts, graphs)</Link>
             
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/reporting/export.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Exporting options (PDF, Excel, CSV)</Link>
            
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/reporting/access-control.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Role-based access control</Link>
            
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/reporting/platform.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Integration with other platforms</Link>

            </div>
          </div>
        </div>
      </div>

      <section className="relative md:py-24 py-16 ">
        <div className="container relative">
          <div className='text-center pb-16'>
            {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
            <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Why Choose Us? </h3>
            <p class="text-slate-400  mt-2 width80 m-auto">Trust in our expertise to transform your data into actionable intelligence.</p>
          </div>
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Real-Time Data Integration</h3>
                <p className="text-slate-400 dark:text-gray-400">Access up-to-date information instantly, enabling you to make informed decisions based on the unique aspects of your latest data.</p>
                
                
              </div>
            </div>
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src='images/reporting/reporting4.jpg' alt="" />
            </div>
          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/reporting/reporting3.jpg" alt="" />
            </div>
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Automated Reporting</h3>
                <p className="text-slate-400 dark:text-gray-400">Say goodbye to manual data entry and time-consuming report generation. Our software automates repetitive tasks, freeing up your valuable time for more strategic activities.</p>

              </div>
            </div>

          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Advanced Analytics</h3>
                <p className="text-slate-400 dark:text-gray-400">Gain valuable insights from your data with our advanced analytics tools, allowing multiple users to access, helping you identify trends, patterns, and opportunities.</p>
                
              </div>
            </div>
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/reporting/reporting2.jpg" alt="" />
            </div>


          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/reporting/reporting1.jpg" alt="" />
            </div>
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Secure Data Handling</h3>
                <p className="text-slate-400 dark:text-gray-400">Rest assured that your sensitive information is protected with our robust security measures, ensuring confidentiality and compliance with data regulations.</p>
                
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className='py-16 mt-9 w-full table relative bg-indigo-600 mb-12'>
        <div className='container'>
          <h3 class="mb-4 md:text-4xl text-3xl lg:leading-normal leading-normal text-white font-semibold text-center">Ready to Experience Seamless Reporting?</h3>
          <p class="text-white/80 max-w-xl mx-auto lg:ms-auto  text-center">Contact us today to schedule a demo and see firsthand how our reporting software can revolutionize the way you approach reporting tasks.</p>
          <div class="mt-2 d-flex justify-content-center" style={{ justifyContent: "center", display: 'flex' }}>
            <div className="mt-6">
                <Link to="/contact-one" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Contact Us</Link>
              {/* <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Start Free</Link> */}
            </div>
          </div>
        </div>
      </section>



      <Footer />
      <CookieModal />
    </>
  )
}
