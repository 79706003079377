import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';

import Startup_SVG from '../../assets/images/illustrator/Startup_SVG.svg';
import SEO_SVG from '../../assets/images/illustrator/SEO_SVG.svg';
import Encryption from '../../assets/images/about/encryption.png';
import classic03 from '../../assets/images/saas/classic03.png';

import AppIcon from '../../assets/images/client/appIcon.png';
import encryptionIcon from '../../assets/images/client/encryptionIcon.png';
import Mobile_notification_SVG from '../../assets/images/illustrator/Mobile_notification_SVG.svg';
import Blog1 from '../../assets/images/blog/bg1.jpg';
import LaptopScreen from '../../assets/images/client/laptopScreen.png';

import TinySlider from 'tiny-slider-react';
import '../../../node_modules/tiny-slider/dist/tiny-slider.css'

import Navbar from '../../component/Navbar/navbar';
import Footer from '../../component/Footer/footer';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';
import GetInTuch from '../../component/getInTuch';
import BlogUserDetail from '../../component/blogUserDetail';
import { blogData } from '../../data/data';

import * as Icon from 'react-feather';
import {FaRegEnvelope, BiBookAlt, BsCheckCircle, MdKeyboardArrowRight} from '../../assets/icons/icons'
import { feedback } from '../../data/data';

import { startupAbout, startupServices } from '../../data/dataTwo';
import { accordionData } from '../../data/dataTwo';


const settings = {
  container: '.tiny-two-item',
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  controlsText: ['<i className="mdi mdi-chevron-left"></i>', '<i className="mdi mdi-chevron-right"></i>'],
  nav: false,
  speed: 400,
  gutter: 0,
  responsive: {
    768: {
      items: 2
    },
  },
}
export default function Product() {

  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);

  let toggleAccordion = (index) => {
      if (activeIndex === index) {
          setActiveIndex(0);
      } else {
          setActiveIndex(index);
      }
  }
  
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
    
  }, []);



  return (
    <div >

      <Navbar />

      <section className="relative table w-full py-36 ">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="md:col-span-7">
              <div className="me-6">
              {/* <h6 class="mb-4 text-base font-medium text-indigo-600">Mobile + TV Apps</h6> */}
                <h4 className="font-semibold lg:leading-normal heading leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">Our Creativity Is  Your  <br/><span className="text-indigo-600"> Success</span></h4>
                <p className="text-slate-400 text-lg max-w-xl text-justify">Tackle the need for familiarizing students with the SSC exam structure. Learnyst’s platform offers a realistic SSC exam environment,  closely emulate the actual SSC exam pattern,</p>

                <div className="mt-6">
                  <Link to="/contact-one" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaRegEnvelope  className="me-2 text-sm "/> Get Started</Link>
                  {/* <Link to="/documentation" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md mt-2"> Book Demo</Link> */}
                </div>
              </div>
            </div>

            <div className="md:col-span-5">
              <img src='images/vocation_banner.png' alt="" />
            </div>
          </div>
        </div>
      </section>

      <div class="container relative ">
          <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
              <div class="lg:col-span-12 md:col-span-6">
              <div>
                <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MARKETING PRODUCTS</h6>
                <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Supercharge your marketing funnels</h3>
                <p class="text-slate-400 max-w-xl mt-2">Explore and learn more about everything from machine learning and global payments to scaling your team.</p></div>
                  <div class="grid lg:grid-cols-3 grid-cols-1 gap-[30px] py-16">
                      <div class="blog relative text-center rounded-md shadow dark:shadow-gray-800 overflow-hidden bg-gray-50 pt-5">
                          {/* <img src={Blog}/> */}
                          <img src='/images/solution_img5.svg' alt="" className='width70 m-auto'/>
  
                          <div class="content p-6">
                              <a href="blog-detail.html" class="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Targeted traffic generation</a>
                              <p class="text-slate-400 mt-3">Drive qualified traffic to your courses and skyrocket your sales with SEO optimized website</p>
                              
                              {/* <div class="mt-4">
                                  <a href="blog-detail.html" class="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out text-center">Read More <i class="uil uil-arrow-right"></i></a>
                              </div> */}
                          </div>
                      </div>
  
                      <div class="blog relative text-center rounded-md shadow dark:shadow-gray-800 overflow-hidden bg-gray-50 pt-5">
                      <img src='/images/solution_Img6.svg' alt="" className='width70 m-auto'/>


  
                          <div class="content p-6">
                              <a href="blog-detail.html" class="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">How apps is changing the IT world</a>
                              <p class="text-slate-400 mt-3 text-center">Easily package and present your expertise to share with students — on their schedule and preferred device.</p>
                              
                              {/* <div class="mt-4">
                                  <a href="blog-detail.html" class="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out text-center">Read More <i class="uil uil-arrow-right"></i></a>
                              </div> */}
                          </div>
                      </div>
  
                      <div class="blog relative text-center rounded-md shadow dark:shadow-gray-800 overflow-hidden bg-gray-50 pt-5">
                        <img src='/images/solution_Img7.svg' alt="" className='width70 m-auto'/>

                          <div class="content p-6">
                              <a href="blog-detail.html" class="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out m-auto text-center d-flex">Smartest Applications for Business</a>
                              <p class="text-slate-400 mt-3 m-auto text-center">The phrasal sequence of the is now so that many campaign and benefit</p>
                              
                              {/* <div class="mt-4">
                                  <a href="blog-detail.html" class="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out text-center">Read More <i class="uil uil-arrow-right"></i></a>
                              </div> */}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <section className="relative pb-16 ">
        <div className="container relative">
          {/* <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">How It Work ?</h3>

            <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>
          </div> */}
                      <div className='text-center pb-16'>
                        {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
                        <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Why SSC Institutes Choose Learnyst</h3>
                        <p class="text-slate-400  mt-2">Customers with their own apps see a 2x increase in monthly recurringrevenue because <br/>audiences love having their membership on their phones.</p>
                      </div>
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                   
              <div className="lg:col-span-7">
                <div className="lg:ms-10">
                  
                  <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6>
                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">SSC Exam Pattern Replication</h3>
                  <p className="text-slate-400 max-w-xl">Whether you're an organisation, educational institution, or training provider, our solution combines top-tier security with a comprehensive LMS to support your learning and development initiatives.</p>

                  {/* <div className="mt-4">
                    <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                  </div> */}
                </div>
              </div>
            <div className="lg:col-span-5">
              <img src='images/solution_banner.png' alt="" />
            </div>
            
          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5">
              <img src="images/solution_img4.png" alt="" />
            </div>
            <div className="lg:col-span-7">
                <div className="lg:ms-10">
                  
                  <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6>
                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Leading Video Encryption and LMS Solution.</h3>
                  <p className="text-slate-400 max-w-xl">Whether you're an organisation, educational institution, or training provider, our solution combines top-tier security with a comprehensive LMS to support your learning and development initiatives.</p>

                  {/* <div className="mt-4">
                    <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                  </div> */}
                </div>
              </div>
            
          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-7">
                <div className="lg:ms-10">
                  
                  <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6>
                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Leading Video Encryption and LMS Solution.</h3>
                  <p className="text-slate-400 max-w-xl">Whether you're an organisation, educational institution, or training provider, our solution combines top-tier security with a comprehensive LMS to support your learning and development initiatives.</p>

                  {/* <div className="mt-4">
                    <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                  </div> */}
                </div>
              </div>
            <div className="lg:col-span-5">
              <img src="images/solution_img2.png" alt="" />
            </div>
            
            
          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5">
              <img src="images/solution_img3.png" alt="" />
            </div>
            <div className="lg:col-span-7">
                <div className="lg:ms-10">
                  
                  <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6>
                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Leading Video Encryption and LMS Solution.</h3>
                  <p className="text-slate-400 max-w-xl">Whether you're an organisation, educational institution, or training provider, our solution combines top-tier security with a comprehensive LMS to support your learning and development initiatives.</p>

                  {/* <div className="mt-4">
                    <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                  </div> */}
                </div>
              </div>
            
          </div>
        </div>
      </section>

        <section className="relative pt-5 pb-16 bg-gray-50 dark:bg-slate-800">
            <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                    <div className="relative order-1 md:order-2">
                        <img src={classic03} className="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
                    </div>

                    <div className="lg:me-8 order-2 md:order-1">
                            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Custom Moodle solutions for your unique challenges</h3>
                        {/* <h4 className="mb-4 text-2xl leading-normal font-medium">Custom Moodle solutions for your unique challenges</h4> */}
                        <p className="text-slate-400">Due to its widespread use as filler text for layouts, non-readability is importance: human perception is tuned to recognize certain patterns and repetitions in texts. If the distribution of letters visual impact.</p>
                        <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-1 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Get a customised Moodle LMS solution to meet  requirements</li>
                            <li className="mb-1 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Integrate Moodle with  platforms  efficient e-Learning ecosystem.</li>
                            <li className="mb-1 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2" />Incorporate your branding to create an intuitive Moodle theme</li>
                            <li className="mb-1 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Integrate Moodle with  platforms  efficient e-Learning ecosystem.</li>
                            <li className="mb-1 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Get a customised Moodle LMS solution to meet  requirements</li>

                        </ul>

                        <div className="mt-4">
                            <Link to="/contact-one" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaRegEnvelope  className="me-2 text-sm "/> Get Started</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

           {/* faqs Start here */}
      <div className="container relative pt-5 mt-9">
        <div className="grid grid-cols-1 pb-3 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Frequently Asked Questions</h3>

            <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>
        </div>

        <div className="relative grid md:grid-cols-12 grid-cols-1 items-center  gap-[30px]">
            <div className="md:col-span-6">
                <div className="relative">
                    <div className="relative rounded-xl overflow-hidden ">
                        {/* <div className="w-full py-72 bg-slate-400 bg-no-repeat bg-top bg-cover jarallax" data-jarallax data-speed="0.5"> */}
                          <img src='images/13330330_Feb-Business_9.svg'alt="" className=''/>
                        {/* </div> */}
                    </div>
                </div>
            </div>

            <div className="md:col-span-6">
                <div id="accordion-collapse">
                    {accordionData.slice(0,4).map((item, index) => (
                        <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                            <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                <button type="button" onClick={() => toggleAccordion(index)} className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                    <span>{item.title}</span>
                                    <svg data-accordion-icon className={`${activeIndex === index ? "rotate-180" : "rotate-270" } size-4 shrink-01`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </button>
                            </h2>
                            {activeIndex === index && (
                                <div>
                                    <div className="p-5">
                                        <p className="text-slate-400 dark:text-gray-400">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                                    </div>
                                </div>
                            )}
                        </div>

                    ))}
                </div>
            </div>
        </div>
      </div>

      <section className='py-16 mt-9 w-full table relative bg-indigo-600 mb-12'>
        <div className='container'>
            <h3 class="mb-4 md:text-4xl text-3xl lg:leading-normal leading-normal text-white font-semibold text-center">Start monetizing your knowledge for FREE</h3>
            <p class="text-white/80 max-w-xl mx-auto lg:ms-auto  text-center">Join our 150,000+ creators who use Graphy to create courses, sell digital products, run webinars, and more</p>
          <div class="mt-2 d-flex justify-content-center" style={{justifyContent: "center",display:'flex'}}>
            <div className="mt-6">
                  <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Book Demo</Link>
                  <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Start Free</Link>
              </div>
            </div>
        </div>
      </section>
    

      <Footer />

      <CookieModal />

     
    </div>
  )
}
