import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';

import Startup_SVG from '../../assets/images/illustrator/Startup_SVG.svg';
import SEO_SVG from '../../assets/images/illustrator/SEO_SVG.svg';
import Encryption from '../../assets/images/about/encryption.png';
import classic03 from '../../assets/images/saas/classic03.png';

import AppIcon from '../../assets/images/client/appIcon.png';
import encryptionIcon from '../../assets/images/client/encryptionIcon.png';
import Mobile_notification_SVG from '../../assets/images/illustrator/Mobile_notification_SVG.svg';
import Blog1 from '../../assets/images/blog/bg1.jpg';
import LaptopScreen from '../../assets/images/client/laptopScreen.png';

import TinySlider from 'tiny-slider-react';
import '../../../node_modules/tiny-slider/dist/tiny-slider.css'

import Navbar from '../../component/Navbar/navbar';
import Footer from '../../component/Footer/footer';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';
import GetInTuch from '../../component/getInTuch';
import BlogUserDetail from '../../component/blogUserDetail';
import { blogData } from '../../data/data';

import * as Icon from 'react-feather';
import {FaRegEnvelope, BiBookAlt, BsCheckCircle, MdKeyboardArrowRight} from '../../assets/icons/icons'
import { feedback } from '../../data/data';
import { corporateAbout2, corporateServices2 } from '../../data/dataTwo';


import { about } from '../../data/dataTwo';
import {MdOutlineEventNote} from '../../assets/icons/icons'


import { startupAbout, startupServices } from '../../data/dataTwo';
import { accordionData } from '../../data/dataTwo';


const settings = {
  container: '.tiny-two-item',
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  controlsText: ['<i className="mdi mdi-chevron-left"></i>', '<i className="mdi mdi-chevron-right"></i>'],
  nav: false,
  speed: 400,
  gutter: 0,
  responsive: {
    768: {
      items: 2
    },
  },
}
export default function Product() {

  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);

  let toggleAccordion = (index) => {
      if (activeIndex === index) {
          setActiveIndex(0);
      } else {
          setActiveIndex(index);
      }
  }
  
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
    
  }, []);



  return (
    <div >

      <Navbar />

      <section className="relative table w-full py-36 pb-24 ">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="md:col-span-7">
              <div className="me-6">
              {/* <h6 class="mb-4 text-base font-medium text-indigo-600">Mobile + TV Apps</h6> */}
                <h4 className="font-semibold lg:leading-normal heading leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">Secure Content with  <br/>Click   <span className="text-indigo-600"> Button</span></h4>
                <p className="text-slate-400 text-lg max-w-xl">Tackle the need for familiarizing students with the SSC exam structure. Learnyst’s platform offers a realistic SSC exam environment,  including sectional timings and a variety of question types.</p>

                <div className="mt-6">
                  <Link to="/contact-one" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaRegEnvelope  className="me-2 text-sm "/> Get Started</Link>
                  {/* <Link to="/documentation" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md mt-2"> Book Demo</Link> */}
                </div>
              </div>
            </div>

            <div className="md:col-span-5">
              <img src='images/integrated_banner.png' alt="" />
            </div>
          </div>
        </div>
      </section>

      <div className="container relative pb-24">
        <div className='display-flex justify-content-center'>
        <h3 className="mb-4 md:text-3xl  md:leading-normal text-center text-2xl leading-normal font-semibold">The Industries We Serve</h3>
        <p class="text-slate-400   pb-12 text-center">Customers with their own apps see a 2x increase in monthly recurringrevenue because <br/>audiences love having their membership on their phones.</p>

          <img src='images/feature_laptop.png' alt="" className='m-auto  width70'/>
        </div>

            <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('../../assets/images/map.png')] bg-no-repeat bg-center bg-cover"></div>

            <div className="relative grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-24 gap-[30px]">
                {corporateServices2.map((item, index)=>{
                    let Icons = item.icon
                    return(
                    <div key={ index } className="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
                          <Icons className="size-7 text-indigo-600"/>

                        <div className="content mt-6">
                            <Link className="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out">Rich and flexible exams</Link>
                            <p className="text-slate-400 mt-4">Set up your exams exactly the way you imagined. Add different types of questions</p>

                            {/* <div className="mt-5">
                                <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More</Link>
                            </div> */}
                        </div>
                    </div>
                    )
                })}
            </div>
      </div>

      <section className="relative pb-16 ">
        <div className="container relative">
          {/* <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">How It Work ?</h3>

            <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>
          </div> */}
                      <div className='text-center pb-16'>
                        {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
                        <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Why SSC Institutes Choose Learnyst</h3>
                        <p class="text-slate-400  mt-2">Customers with their own apps see a 2x increase in monthly recurringrevenue because <br/>audiences love having their membership on their phones.</p>
                      </div>
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                   
              <div className="lg:col-span-7">
                <div className="lg:ms-10">
                  
                  <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6>
                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">SSC Exam Pattern Replication</h3>
                  <p className="text-slate-400 max-w-xl">Whether you're an organisation, educational institution, or training provider, our solution combines top-tier security with a comprehensive LMS to support your learning and development initiatives.</p>

                  {/* <div className="mt-4">
                    <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                  </div> */}
                </div>
              </div>
            <div className="lg:col-span-5">
              <img src='images/feature_banner.png' alt="" />
            </div>
            
          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5">
              <img src="images/feature_img1.jpg" alt="" />
            </div>
            <div className="lg:col-span-7">
                <div className="lg:ms-10">
                  
                  <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6>
                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Leading Video Encryption and LMS Solution.</h3>
                  <p className="text-slate-400 max-w-xl">Whether you're an organisation, educational institution, or training provider, our solution combines top-tier security with a comprehensive LMS to support your learning and development initiatives.</p>

                  {/* <div className="mt-4">
                    <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                  </div> */}
                </div>
              </div>
            
          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-7">
                <div className="lg:ms-10">
                  
                  <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6>
                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Leading Video Encryption and LMS Solution.</h3>
                  <p className="text-slate-400 max-w-xl">Whether you're an organisation, educational institution, or training provider, our solution combines top-tier security with a comprehensive LMS to support your learning and development initiatives.</p>

                  {/* <div className="mt-4">
                    <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                  </div> */}
                </div>
              </div>
            <div className="lg:col-span-5">
              <img src="images/feature_img2.jpg" alt="" />
            </div>
            
            
          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5">
              <img src="images/feature_img3.jpg" alt="" />
            </div>
            <div className="lg:col-span-7">
                <div className="lg:ms-10">
                  
                  <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6>
                  <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Leading Video Encryption and LMS Solution.</h3>
                  <p className="text-slate-400 max-w-xl">Whether you're an organisation, educational institution, or training provider, our solution combines top-tier security with a comprehensive LMS to support your learning and development initiatives.</p>

                  {/* <div className="mt-4">
                    <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                  </div> */}
                </div>
              </div>
            
          </div>
        </div>
      </section>

      {/* What we Do */}

      <section className="relative mt-9">
                <div className="container relative md:mb-24 mb-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">What we do ?</h3>
                        <p className="text-slate-400 max-w-xl mx-auto">Create, collaborate, and turn your ideas into incredible products with the definitive platform for digital design.</p>
                    </div>

                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        {
                            about.map((data,index) => {
                                const Icons = data.icon
                                return (
                                    <div key={index} className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                                        <div className="relative overflow-hidden text-transparent -m-3">
                                            <Icon.Hexagon className="size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"></Icon.Hexagon>
                                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                              <img src="images/what_we_do.svg" alt="" />
                                            </div>
                                        </div>

                                        <div className="mt-6">
                                        <a href="" class="text-lg font-medium group-hover:text-white duration-500">Certification</a>
                                            <Link to="#" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">{data.title}</Link>
                                            <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">It is a long established fact that a reader.</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>

        

        <section className="py-16 w-full table relative bg-indigo-600 mb-12">
        <div className="absolute inset-0 bg-[url('../../assets/images/insurance/cta.png')] bg-bottom bg-no-repeat bg-cover"></div>
        <div className="container relative">
            <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                <div className="order-2 lg:order-1 lg:mt-0 mt-8">
                    <div className="lg:me-6 text-center lg:text-start">
                        <h3 className="mb-4 md:text-4xl text-3xl lg:leading-normal leading-normal text-white font-semibold">Simplify your teaching with our best features</h3>
                        <p className="text-white/80 max-w-xl mx-auto lg:ms-auto">Explore and learn more about everything from machine learning and global payments to scaling your team.</p>

                        <div className="mt-6">
                            <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Book Demo</Link>
                        </div>
                    </div>
                </div>

                <div className="order-1 laptop_screen_box lg:me-6 lg:order-2">
                  <img src={LaptopScreen} alt="" className='laptop_screen'/>
                </div>
            </div>
        </div>
    </section>

      
    

      <Footer />

      <CookieModal />

     
    </div>
  )
}
