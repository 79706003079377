import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';

import Startup_SVG from '../../assets/images/illustrator/Startup_SVG.svg';
import SEO_SVG from '../../assets/images/illustrator/SEO_SVG.svg';
import Encryption from '../../assets/images/about/encryption.png';
import AppIcon from '../../assets/images/client/appIcon.png';
import encryptionIcon from '../../assets/images/client/encryptionIcon.png';
import Mobile_notification_SVG from '../../assets/images/illustrator/Mobile_notification_SVG.svg';
import Blog1 from '../../assets/images/blog/bg1.jpg';
import LaptopScreen from '../../assets/images/client/laptopScreen.png';

import TinySlider from 'tiny-slider-react';
import '../../../node_modules/tiny-slider/dist/tiny-slider.css'

import Navbar from '../../component/Navbar/navbar';
import Footer from '../../component/Footer/footer';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';
import GetInTuch from '../../component/getInTuch';
import BlogUserDetail from '../../component/blogUserDetail';
import { blogData } from '../../data/data';

import * as Icon from 'react-feather';
import {FaRegEnvelope, BiBookAlt, BsCheckCircle, MdKeyboardArrowRight} from '../../assets/icons/icons'
import { feedback } from '../../data/data';

import { startupAbout, startupServices } from '../../data/dataTwo';
import { accordionData } from '../../data/dataTwo';


const settings = {
  container: '.tiny-two-item',
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  controlsText: ['<i className="mdi mdi-chevron-left"></i>', '<i className="mdi mdi-chevron-right"></i>'],
  nav: false,
  speed: 400,
  gutter: 0,
  responsive: {
    768: {
      items: 2
    },
  },
}
export default function Product() {

  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);

  let toggleAccordion = (index) => {
      if (activeIndex === index) {
          setActiveIndex(0);
      } else {
          setActiveIndex(index);
      }
  }
  
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
    
  }, []);



  return (
    <div >

      <Navbar />

      <section className="relative table w-full py-36 ">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="md:col-span-7">
              <div className="me-6">
              {/* <h6 class="mb-4 text-base font-medium text-indigo-600">Mobile + TV Apps</h6> */}
                <h4 className="font-semibold lg:leading-normal heading leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white"> Unlock New Learning <span className="text-indigo-600">Adventures</span></h4>
                <p className="text-slate-400 text-lg max-w-xl text-justify">Explore, Engage, and Excel with Our Educational TV Apps</p>

                <div className="mt-6">
                  <Link to="" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaRegEnvelope  className="me-2 text-sm "/> Get Started</Link>
                  <Link to="" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md mt-2"> Book Demo</Link>
                </div>
              </div>
            </div>

            <div className="md:col-span-5">
              <img src='images/tv_banner.png' alt="" />
            </div>
          </div>
        </div>
      </section>

    

      


          <section className="relative py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                      <div className=' text-center'>
                        {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
                        <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Why Choose Vidyokrypt's TV Apps?</h3>
                        <p class="text-slate-400  mt-2 ">empowers learners to explore, discover, and excel in their educational journey</p>
                      </div>
                    

                      <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] mt-7 pt-7">
                    <div class="flex">
                        {/* <div class="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
                            <i class="uil uil-water"></i>
                        </div> */}

                        <div class="content ms-6">
                            <a href="" class="text-lg font-medium hover:text-indigo-600">Accessible Anytime, Anywhere</a>
                            <div className='border-t mt-3'></div>
                            <p class="text-slate-400 mt-3 text-justify">Access our educational TV apps anytime, anywhere whether you're at home, on the go or in the classroom. </p>
                        </div>
                    </div>
                    {/* <!--end feature content--> */}

                    <div class="flex">
                        {/* <div class="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
                            <i class="uil uil-dropbox"></i>
                        </div> */}

                        <div class="content ms-6">
                            <a href="" class="text-lg font-medium hover:text-indigo-600">Accessibility</a>
                            <div className='border-t mt-3'></div>

                            <p class="text-slate-400 mt-3 text-justify">Our TV app include audio descriptions,  and adjustable playback speeds to accommodate users with their learning needs.</p>
                        </div>
                    </div>
                    {/* <!--end feature content--> */}

                    <div class="flex">
                        {/* <div class="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
                            <i class="uil uil-focus-target"></i>
                        </div> */}

                        <div class="content ms-6">
                            <a href="" class="text-lg font-medium hover:text-indigo-600">Cost-Effective</a>
                            <div className='border-t mt-3'></div>

                            <p class="text-slate-400 mt-3 text-justify">We offer a cost-effective alternative to traditional educational resources, providing access to high-quality  content.</p>
                        </div>
                    </div>
                    {/* <!--end feature content--> */}
                </div>
                {/* <!--end grid--> */}
                </div>
            </section>
      
      
      <section className="relative py-16 ">
        <div className="container relative">
            <div className='text-center'>
              {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
              <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Unlock the Power of Learning with These Key Features</h3>
              <p class="text-slate-400  mt-2">Explore Engaging Features in Our Educational TV Apps</p>
            </div>
            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                    
                <div className="lg:col-span-7">
                  <div className="lg:ms-10">
                    
                    {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                    <h3 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Interactive Learning Experience</h3>
                    <p className="text-slate-400 max-w-xl text-justify">Engage with our educational content through advanced interactive quizzes, MCQs videos, and ebooks that reinforce standard key concepts and increase your skills, growth and knowledge base.</p>

                    {/* <div className="mt-4">
                      <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                    </div> */}
                  </div>
                </div>
              <div className="lg:col-span-5">
                <img src='images/learning.png' alt="" />
              </div>
              
            </div>
            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
              <div className="lg:col-span-5">
                <img src="images/Supplemental.png" alt=""  className=''/>
              </div>
              <div className="lg:col-span-7">
                  <div className="lg:ms-10">
                    
                    <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6>
                    <h3 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Supplemental Resources</h3>
                    <p className="text-slate-400 max-w-xl text-justify">Our apps provide supplemental resources such as quizzes, videos, and ebooks that reinforce learning objectives and deepen understanding.</p>

                    {/* <div className="mt-4">
                      <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                    </div> */}
                  </div>
                </div>
              
            </div>

            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
              <div className="lg:col-span-7">
                  <div className="lg:ms-10">
                    
                    <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6>
                    <h3 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Teacher Tools and Resources</h3>
                    <p className="text-slate-400 max-w-xl text-justify">Educators can leverage our platform to enhance classroom instruction with supplemental videos, lesson plans, and assessment tools.</p>

                    {/* <div className="mt-4">
                      <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                    </div> */}
                  </div>
                </div>
              <div className="lg:col-span-5 d-flex justify-content-end">
                <img src="images/Teacher.png" alt="" className='width90 m-end'/>
              </div>
              
              
            </div>

            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
              <div className="lg:col-span-5">
                <img src="images/track.png" alt="" />
              </div>
              <div className="lg:col-span-7">
                  <div className="lg:ms-10">
                    
                    <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6>
                    <h3 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Track Progress</h3>
                    <p className="text-slate-400 max-w-xl text-justify">Users can track their learning progress and achievements within the app, providing valuable feedback and motivation to continue learning.</p>

                    {/* <div className="mt-4">
                      <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                    </div> */}
                  </div>
                </div>
              
            </div>
        </div>
      </section>


      {/* faqs Start here */}
      <div className="container relative pt-5">
        <div className="grid grid-cols-1 pb-5 text-center">
            <h3 className="mb-3 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Frequently Asked Questions (FAQs)</h3>

            <p className="text-slate-400 max-w-xl mx-auto">Have questions about using Vidyokrypt's TV Apps? Explore our FAQs section for answers to common inquiries.  </p>
        </div>

        <div className="relative grid md:grid-cols-12 grid-cols-1 items-center  gap-[30px]">
            <div className="md:col-span-6">
                <div className="relative">
                    <div className="relative rounded-xl overflow-hidden ">
                        {/* <div className="w-full py-72 bg-slate-400 bg-no-repeat bg-top bg-cover jarallax" data-jarallax data-speed="0.5"> */}
                          <img src='images/13330330_Feb-Business_9.svg'alt="" className=''/>
                        {/* </div> */}
                    </div>
                </div>
            </div>

            <div className="md:col-span-6">
                <div id="accordion-collapse">
                    {accordionData.slice(0,4).map((item, index) => (
                        <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                            <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                <button type="button" onClick={() => toggleAccordion(index)} className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                    <span>How does it work?</span>
                                    <svg data-accordion-icon className={`${activeIndex === index ? "rotate-180" : "rotate-270" } size-4 shrink-01`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </button>
                            </h2>
                            {activeIndex === index && (
                                <div>
                                    <div className="p-5">
                                        <p className="text-slate-400 dark:text-gray-400">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                                    </div>
                                </div>
                            )}
                        </div>

                    ))}
                </div>
            </div>
        </div>
      </div>

      <section className="py-16 w-full table relative bg-indigo-600 mb-12">
        <div className="absolute inset-0 bg-[url('../../assets/images/insurance/cta.png')] bg-bottom bg-no-repeat bg-cover"></div>
        <div className="container relative">
            <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                <div className="order-2 lg:order-1 lg:mt-0 mt-8">
                    <div className="lg:me-6 text-center lg:text-start">
                        <h3 className="mb-4 md:text-4xl text-3xl lg:leading-normal leading-normal text-white font-semibold">Elevate Your Learning Experience</h3>
                        <p className="text-white/80 max-w-xl mx-auto lg:ms-auto">Join us on a journey of discovery and exploration as we harness the power of TV apps to empower learners around the world.</p>
                        <div className="mt-6">
                            <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Book Demo</Link>
                        </div>
                    </div>
                </div>

                <div className="order-1 laptop_screen_box lg:me-6 lg:order-2">
                  <img src='images/insitute.png' alt="" className='laptop_screen'/>
                </div>
            </div>
        </div>
      </section>
      

      

    

      <Footer />

      <CookieModal />

     
    </div>
  )
}
