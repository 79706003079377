import React, {useState} from 'react'
import { Link } from 'react-router-dom';

import logo_icon_40 from '../../assets/images/logo-icon-40.png';
import ab03 from '../../assets/images/about/ab03.jpg';
import ab02 from '../../assets/images/about/ab02.jpg';
import ab01 from '../../assets/images/about/ab01.jpg';
import background from '../../assets/images/startup/02.png';
import imageP from '../../assets/images/digital/02.jpg';
import image1 from '../../assets/images/digital/01.jpg';
import image2 from '../../assets/images/digital/03.jpg';
import image3 from '../../assets/images/digital/04.jpg';

import Navbar from '../../component/Navbar/navbar'
import ManagementTeam from '../../component/managementTeam';
import CookieModal from '../../component/cookieModal';
import SmallFooter from '../../component/Footer/smallFooter';
import CompanyLogo from '../../component/companyLogo';
import Footer from '../../component/Footer/footer';
import { mobileNumber,phoneFeature } from '../../data/dataFour';

import * as Icon from 'react-feather';
import { FaRegEnvelope, FaArrowRight} from '../../assets/icons/icons'
import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";
import Cta from '../../component/cta';

import { startupAbout, startupServices } from '../../data/dataTwo';

import axios from "axios";
import SweetAlert2 from 'react-sweetalert2';
import Swal from 'sweetalert2'

export default function IndexStartup() {

    const [name,setName] = useState();
    const [email,setEmail] = useState();
    const [question,setQuestion] = useState();
    const [comment,setComment] = useState();

    const handleSubmit = (event) => {
        event.preventDefault();
    
    
            let data = JSON.stringify({
            "name": name,
            "email": email,
            "question": question,
            "comment": comment,
    
            });
    
            let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://edukrypt.com/api/sendEmailContactUs',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
            };
    
            axios.request(config)
            .then((response) => {
    
                document.getElementById("create-course-form").reset();
                Swal.fire(response.data.message);
    
    
    
            })
            .catch((error) => {
            console.log(error);
            });
    
    
      };


    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <Navbar navClass="nav-sticky" />
            {/* <CompanyLogo/> */}
            <div className="container relative  mt-24 border-bottom border-top pb-7">
                <div className="grid lg:grid-cols-6 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    <div className="group relative p-1 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center hover-gray">
                        <div className="relative overflow-hidden text-transparent -m-3">
                                <img src='images/icons/hardware/handy5.png' alt="" className='hardware_icon  m-auto mt-4'/>
                        </div>

                        <div className="mt-6">
                            <Link to="/handy_pos" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out hardware_box_font">Handy POS</Link>
                        </div>
                    </div>

                    <div className="group relative p-1 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center hover-gray">
                        <div className="relative overflow-hidden text-transparent -m-3">
                                <img src='images/icons/hardware/thermal2.png' alt="" className='hardware_icon m-auto mt-4'/>
                            
                        </div>

                        <div className="mt-5">
                            <Link to="/label_printer" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out hardware_box_font">Printers</Link>
                        </div>
                    </div>

                    <div className="group relative p-1 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center hover-gray">
                        <div className="relative overflow-hidden text-transparent -m-3">
                                <img src='images/icons/hardware/1111.png' alt="" className='hardware_icon  m-auto mt-4'/>
                            
                        </div>

                        <div className="mt-5">
                            <Link to="/android_pos" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out hardware_box_font">Android POS</Link>
                        </div>
                    </div>

                    <div className="group relative p-1 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center hover-gray">
                        <div className="relative overflow-hidden text-transparent -m-3">
                                <img src='images/icons/hardware/android2.png' alt="" className='hardware_icon m-auto mt-4'/>
                            
                        </div>

                        <div className="mt-5">
                            <Link to="/windows_pos" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out hardware_box_font">Windows POS</Link>
                        </div>
                    </div>

                    {/* <div className="group relative p-1 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center hover-gray">
                        <div className="relative overflow-hidden text-transparent -m-3">
                                <img src='images/icons/hardware/thermal2.png' alt="" className='hardware_icon m-auto mt-4'/>
                            
                        </div>

                        <div className="mt-5">
                            <Link to="/thermal_printer" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out hardware_box_font">Thermal Printer</Link>
                        </div>
                    </div> */}

                    <div className="group relative p-1 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center hover-gray">
                        <div className="relative overflow-hidden text-transparent -m-3">
                                <img src='images/icons/hardware/barcode.png' alt="" className='hardware_icon m-auto mt-4'/>
                            
                        </div>

                        <div className="mt-5">
                            <Link to="/barcode_scanner" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out hardware_box_font">Barcode Scanner</Link>
                        </div>
                    </div>

                    <div className="group relative p-1 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center hover-gray">
                        <div className="relative overflow-hidden text-transparent -m-3">
                                <img src='images/icons/hardware/other2.png' alt="" className='hardware_icon  m-auto mt-4'/>
                            
                        </div>

                        <div className="mt-5">
                            <Link to="#" className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out hardware_box_font">Aceessories </Link>
                        </div>
                    </div>
                    
                </div>
            </div>


            <section className="relative table w-full bg-gray-50 pb-9">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                        <div className="md:col-span-6">
                            <div className="md:me-6">
                                {/* <p className="text-indigo-600 text-lg font-medium mb-4">Welcome To Hardware</p> */}
                                <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">Discover Our Handy POS Software</h4>
                                <p className="text-slate-400 text-lg max-w-xl">Revolutionize your business with our handy POS software – streamline transactions, manage inventory effortlessly, and boost sales like never before!"</p>

                                <div className="mt-6">
                                    <Link to="/get-started"  className="py-2 px-5  inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link>
                                    {/* <Link className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full me-1">Get Started</Link> */}
                                </div>
                            </div>
                        </div>
                        <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} />

                        <div className="md:col-span-6">
                            <div className="grid grid-cols-12 gap-4 items-center">
                                <div className="col-span-5">
                                    <div className="grid grid-cols-1 gap-4">
                                        <img src='images/handy/handy17.jpg' className="shadow rounded-lg" alt="" />
                                        <div className="ms-auto">
                                            <div className=" bg-indigo-600/10 rounded-lg ">
                                                <img src='images/handy/handy6.jpg' className="shadow rounded-lg pos_img" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-7">
                                    <div className="grid grid-cols-1 gap-4">
                                        <img src='images/handy/handy20.png' className="shadow rounded-lg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container relative mt-24">
                    <div className="grid grid-cols-1 pb-4 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Why Choose Us?</h3>
                        <p className="text-slate-400 max-w-xl mx-auto">Our handy POS software stands out for its user-friendly interface, robust features, and seamless integration capabilities.</p>
                    </div>

                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                        <div  className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center border shadow py-5">
                            <div className="relative overflow-hidden text-transparent -m-3">
                                <Icon.Hexagon className="size-28 fill-indigo-600/5 mx-auto rotate-[30deg]"></Icon.Hexagon>
                                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                    <img src='images/icons/hardware/user-friendly.png' alt="" className='appIcon hardware_icon2 icon-orange m-auto'/>
                                </div>                            </div>

                            <div className="mt-6">
                                <Link href="#" className="text-xl font-medium hover:text-indigo-600 duration-500 ease-in-out">User-Friendly Interface</Link>
                                <p className="text-slate-400 transition duration-500 ease-in-out mt-3">Intuitive design and Quick access to essential functions.</p>
                            </div>
                        </div>

                        <div  className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center border shadow py-5">
                            <div className="relative overflow-hidden text-transparent -m-3">
                                <Icon.Hexagon className="size-28 fill-indigo-600/5 mx-auto rotate-[30deg]"></Icon.Hexagon>
                                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                    <img src='images/icons/hardware/improvement.png' alt="" className='appIcon hardware_icon2 icon-orange m-auto'/>
                                </div>
                            </div>

                            <div className="mt-6">
                                <Link href="#" className="text-xl font-medium hover:text-indigo-600 duration-500 ease-in-out">Sales Processing</Link>
                                <p className="text-slate-400 transition duration-500 ease-in-out mt-3">Support for multiple payment methods (cash, credit/debit cards, etc).</p>
                            </div>
                        </div>

                        <div  className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center border shadow py-5">
                            <div className="relative overflow-hidden text-transparent -m-3">
                                <Icon.Hexagon className="size-28 fill-indigo-600/5 mx-auto rotate-[30deg]"></Icon.Hexagon>
                                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                    <img src='images/icons/hardware/shield.png' alt="" className='appIcon hardware_icon2 icon-orange m-auto'/>
                                </div>
                            </div>

                            <div className="mt-6">
                                <Link href="#" className="text-xl font-medium hover:text-indigo-600 duration-500 ease-in-out">Security Features</Link>
                                <p className="text-slate-400 transition duration-500 ease-in-out mt-3">Secure login with user authentication and data encryption protocols.</p>
                            </div>
                        </div>
                    </div>
            </div>
     

            <section className="relative md:py-24 py-16 ">
                <div className="container relative">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]"> 
                    <div className="lg:col-span-7">
                        <div className="lg:ms-10">
                            {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6> */}
                            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Secure Payment Processing </h3>
                            <p className="text-slate-400 max-w-xl">Accept all major credit cards, mobile payments, and other digital transactions securely. Our system ensures that your customers' payment information is always protected.</p>
                            </div>
                        </div>
                        <div className="lg:col-span-5 d-flex justify-end">
                            <img src='images/handy/handy5.jpg' alt="" className=' p-3 border shadow ' />
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]"> 
                        <div className="lg:col-span-5 d-flex justify-start">
                            <img src='images/handy/handy11.jpg' alt="" className=' p-3 border shadow ' />
                        </div>
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">
                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Customer Relationship Management </h3>
                                <p className="text-slate-400 max-w-xl">Build stronger relationships with your customers using our built-in CRM tools. Track purchase history and personalize your marketing efforts to keep your customers coming back.</p>
                            </div>
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]"> 
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">
                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Comprehensive Sales Reporting </h3>
                                <p className="text-slate-400 max-w-xl">Gain valuable insights into your sales performance with detailed reports. Analyze trends, track performance, and make informed decisions to boost your revenue.</p>
                            </div>
                        </div>
                        <div className="lg:col-span-5 d-flex justify-end">
                            <img src='images/handy/handy10.jpg' alt="" className=' p-3 border shadow ' />
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]"> 
                        <div className="lg:col-span-5 d-flex justify-start">
                            <img src='images/handy/handy3.jpg' alt="" className=' p-3 border shadow ' />
                        </div>
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">
                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Multi-Store Management </h3>
                                <p className="text-slate-400 max-w-xl">Operate multiple locations with ease. Our centralized system allows you to manage inventory, sales, and staff across all your stores from a single dashboard.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="container relative mt-12 ">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                    <div className="lg:col-span-12 md:col-span-6">
                    <div>
                        <h3 className="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0 ">Innovative Hardware Insights for the Modern Business.</h3>
                        <p className="text-slate-400 mt-2 ">Welcome to our blog section, your go-to source for the latest trends, tips, and insights on <br/>cutting-edge hardware solutions.</p>
                    </div>
                        <div className="grid lg:grid-cols-3 grid-cols-1 gap-[30px] py-16 d-flex justify-center ">
                            <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden width100">
                                <img src='images/handy/handy9.jpg' alt="" className=''/>
        
                                <div className="content p-6">
                                <h2 href="#" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Streamline with Handy POS</h2>
                                    <p className="text-slate-400 mt-3">Build strong relationships with your customers through Handy POS's customer management features. </p>
                                </div>
                            </div>

                            <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden width100">
                                <img src='images/handy/handy15.jpg' alt="" className=''/>
        
                                <div className="content p-6">
                                <h2 href="#" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Enhances Retail Efficiency</h2>
                                    <p className="text-slate-400 mt-3">Handy POS software is designed to enhance retail efficiency from the ground up.</p>
                                </div>
                            </div>

                            <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden width100">
                                <img src='images/handy/handy8.jpg' alt="" className=''/>
        
                                <div className="content p-6">
                                <h2 href="#" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Operations with Handy POS</h2>
                                    <p className="text-slate-400 mt-3">From taking orders to managing the kitchen and keeping inventory, it can be a challenge.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="relative pt-2 pb-24 bg-gray-50 dark:bg-slate-800 mt-7">
                <div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                        <div className="relative">
                            <div className="md:me-10">
                                <img src='images/handy/handy2.jpg' className="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
                            </div>
                            
                        </div>

                        <div className="lg:ms-8 mt-8 md:mt-0">
                            <h4 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Streamline Your Sales with Ease</h4>
                            <p className="text-slate-400 text-justify">Our Handy POS is designed to simplify your business operations, enhance customer experience, and drive growth. Whether you run a small boutique or a large retail chain, our powerful and user-friendly software is the perfect solution for all your point of sale needs.</p>

                            {/* <div className="mt-4">
                                <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            

            {/* Contact Form */}
            <div className="container relative mt-24 mb-24">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:me-8">
                            <div className="relative">
                                <img src='images/form2.png' alt="" />
                                <div className="overflow-hidden absolute size-[512px] bg-indigo-600/5 top-1/4 start-0 end-0 align-middle -z-1 rounded-full"></div>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-7 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                                <h3 className="mb-6 text-2xl leading-normal font-medium">Get in touch !</h3>

                                <form id="create-course-form" method='post' onSubmit={handleSubmit}>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.User className="size-4 absolute top-3 start-4"></Icon.User>
                                                    <input onChange={(e) => {setName(e.target.value)}} name="name" id="name" type="text" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Name :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.Mail className="size-4 absolute top-3 start-4"></Icon.Mail>
                                                    <input onChange={(e) => {setEmail(e.target.value)}} name="email" id="email" type="email" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Email :" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.Book className="size-4 absolute top-3 start-4"></Icon.Book>
                                                    <input onChange={(e) => {setQuestion(e.target.value)}} name="subject" id="subject" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Subject :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.MessageCircle className="size-4 absolute top-3 start-4"></Icon.MessageCircle>
                                                    <textarea onChange={(e) => {setComment(e.target.value)}} name="comments" id="comments" className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Message :"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type='submit' className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full">Submit</button>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>           

            <Footer />

<CookieModal />
        </>
    )
}
