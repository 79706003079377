import React, { useState } from 'react'
import { Link } from 'react-router-dom';


import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import CookieModal from '../../component/cookieModal';



import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";

import * as Icon from 'react-feather';
import { FaArrowRight, MdKeyboardArrowRight } from '../../assets/icons/icons'


import { featureData } from '../../data/dataFour';
import { accordionData } from '../../data/dataTwo';
import { TypeAnimation } from 'react-type-animation';


export default function IndexLandingFour() {
  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);


  let toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  }
  return (
    <>
      <Navbar />
      <section className="relative table w-full pt-24 pb-24 bg-gray-50 dark:bg-slate-800 overflow-hidden" id="home">
        <div className="container relative">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 mt-10 items-center gap-[30px]">
            <div className="lg:col-span-6 mt-9">
              {/* <span className="bg-indigo-600/5 text-indigo-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">Form Landing Page</span> */}

              {/* <h4 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mt-2 mb-5">Cutting-Edge POS <br/>Software for, </h4> */}
              <h1 className="font-bold heading lg:leading-normal leading-normal text-5xl lg:text-5xl mb-5">Simplifying Accounting and Bookkeeping for Your Business </h1>

              <p className="text-slate-400 text-lg max-w-xl text-justify">PosKrypt is your one-stop solution for all your accounting and bookkeeping needs. Designed with small to medium-sized businesses in mind, it integrates seamlessly with your accounting processes, ensuring accuracy and ease of use.</p>

              <div className="relative mt-10">
              <Link to="/get-started"  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link>
                {/* <Link className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full me-5 ms-4"> Support Training</Link> */}

              </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} />

            <div className="lg:col-span-6">
              <div className="dark:bg-slate-900 rounded-xl  dark:shadow-gray-800 md:p-8 p-6 lg:ms-10 z-10 relative">
                {/* <h4 className="mb-5 text-2xl font-semibold">Just Trial Form</h4> */}

                  <div className="relative after:content-[''] after:absolute after:md:bottom-12 after:-bottom-20 lg:after:-start-0 md:after:-start-20 after:-start-24
                    after:bg-indigo-600 after:shadow-2xl after:shadow-indigo-600/40 after:-z-1 ltr:after:rotate-[130deg] rtl:after:-rotate-[130deg] after:w-[75rem] after:md:h-[55rem] after:h-[30rem] after:rounded-[20rem]">
                        <img src='images/accounting/accounting1.jpg' className='p-3 border shadow radius-4'></img>
                    </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container relative mt-24">
        <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
          <div className="lg:col-span-12 md:col-span-12 md:text-start text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">Why Choose Us?</h3>
            <p className="text-slate-400  mt-2 width80 m-auto text-center">We offer expert knowledge, personalized solutions, and cutting-edge technology to ensure <br/>your financial records are accurate and up-to-date.</p>
          </div>
        </div>


        <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                  <img src='images/accounting/Accounting8.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-indigo-600 transition-all duration-500 ease-in-out">Comprehensive Accounting Features</Link></h5>
                <span className="text-slate-400 text-justify">From invoicing to expense tracking, our POS system covers all your financial management needs.</span>
              </div>
            </div>
          </div>

          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                  <img src='images/accounting/Accounting7.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">User-Friendly Interface</Link></h5>
                <span className="text-slate-400 text-justify">Intuitive design that’s easy to navigate, ensuring that even non-accountants can manage the books with confidence.</span>
              </div>
            </div>
          </div>

          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                  <img src='images/accounting/Accounting6.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Secure and Reliable</Link></h5>
                <span className="text-slate-400 text-justify">State-of-the-art security measures to protect your sensitive financial data.</span>
              </div>
            </div>
          </div>

          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                  <img src='images/accounting/Accounting5.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Customizable Reports</Link></h5>
                <span className="text-slate-400 text-justify">Generate detailed reports tailored to your business requirements, helping you stay on top of your finances.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container relative mt-24">
        <div className="grid grid-cols-1 pb-8 text-center">
          {/* <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Features</h6> */}
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Features</h3>

          <p className="text-slate-400  mx-auto">With robust features like real-time reporting, invoicing, and seamless integration, managing your finances has never been simpler.</p>
        </div>

        <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/accounting/hand.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Customizable Invoices</Link>
              {/* <p className="text-slate-400 mt-3">Streamline your operations and drive business growth.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/accounting/automation.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Automation</Link>
              {/* <p className="text-slate-400 mt-3">Track stock levels and manage suppliers seamlessly.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/accounting/budgeting.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Budgeting and Forecasting</Link>
              {/* <p className="text-slate-400 mt-3">Monitor data and payroll of  employees</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/accounting/accounting.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Project Accounting</Link>
              {/* <p className="text-slate-400 mt-3">Quick and easy transactions data, minimizing training time.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/accounting/report.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Financial Reporting</Link>
              {/* <p className="text-slate-400 mt-3">Ensure secure payment processing with support.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/accounting/expense.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Expense Tracking </Link>
              {/* <p className="text-slate-400 mt-3">Monitor sales, inventory, and staff across all your locations.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>
        </div>
      </div>

      <section className="relative md:py-24 py-16 ">
        <div className="container relative">
          <div className='text-center pb-16'>
            {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
            <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Benefits of Accounting & Bookkeeping Software </h3>
            <p class="text-slate-400  mt-2 width80 m-auto">Designed to scale with your business, our solution helps you stay compliant and make informed financial decisions effortlessly.</p>
          </div>
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">User-Friendly Interface</h3>
                <p className="text-slate-400 mt-3">Our software is designed to be intuitive and easy to use, requiring minimal training for staff. This ensures quick adoption and smooth operation, even for those without extensive technical skills.</p>
              </div>
            </div>
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src='images/accounting/Accounting1.jpg' alt="" />
            </div>
          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/accounting/Accounting3.jpg" alt="" />
            </div>
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Enhanced Accuracy</h3>
                <p className="text-slate-400 mt-3">Our system minimizes human error by automating data entry and calculations. This ensures that financial records are precise, reducing discrepancies and improving overall accuracy in bookkeeping.</p>
                
              </div>
            </div>

          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Cost Savings</h3>
                <p className="text-slate-400 mt-3">By reducing the time spent on manual bookkeeping and minimizing errors, our system lowers operational costs. It optimizes staff productivity and reduces the need for extensive accounting personnel.</p>
                
              </div>
            </div>
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/accounting/Accounting4.jpg" alt="" />
            </div>


          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/accounting/Accounting9.jpg" alt="" />
            </div>
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Detailed Reporting</h3>
                <p className="text-slate-400 mt-3">Our system provide comprehensive reports on sales, expenses, profits, and other financial metrics. These reports aid in analyzing business performance, identifying trends, and planning for future growth.</p>
                
              </div>
            </div>
          </div>


        </div>
      </section>



      <section className='py-16 mt-9 w-full table relative bg-indigo-600 mb-12'>
        <div className='container'>
          <h3 class="mb-4 md:text-4xl text-3xl lg:leading-normal leading-normal text-white font-semibold text-center">Ready to take control of your finances? </h3>
          <p class="text-white/80 max-w-xl mx-auto lg:ms-auto  text-center">Contact us for a free trial today and experience the difference PosKrypt can make for your business!</p>
          <div class="mt-2 d-flex justify-content-center" style={{ justifyContent: "center", display: 'flex' }}>
            <div className="mt-6">
                <Link to="/contact-one" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Contact Us</Link>
              {/* <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Start Free</Link> */}
            </div>
          </div>
        </div>
      </section>



      <Footer />
      <CookieModal />
    </>
  )
}
