import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
import axios from "axios";

import CookieModal from '../../component/cookieModal';



import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";

import * as Icon from 'react-feather';

import SweetAlert2 from 'react-sweetalert2';
import Swal from 'sweetalert2'

export default function IndexLandingFour() {
    let [isOpen, setOpen] = useState(false)
    let [activeIndex, setActiveIndex] = useState(0);

    const [name,setName] = useState();
    const [email,setEmail] = useState();
    const [institute,setInstitute] = useState();
    const [description,setDescription] = useState();
    const [phone,setPhone] = useState();
    const [subject,setSubject] = useState();
  

    const handleSubmit = (event) => {
        event.preventDefault();
    
    
            let data = JSON.stringify({
            "name": name,
            "email": email,
            "institute": institute,
            "subject": subject,
            "description": description,
            "phone": phone
            });
    
            let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://edukrypt.com/api/sendEmailGetStarted',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
            };
    
            axios.request(config)
            .then((response) => {
    
                document.getElementById("create-course-form").reset();
                Swal.fire(response.data.message);
    
    
    
            })
            .catch((error) => {
            console.log(error);
            });
    
    
      };

      


    let toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    return (
        <>
            <Navbar navclassName="nav-sticky" />


            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] mx-auto text-center">
                        <div className="lg:col-start-3 lg:col-span-8 md:col-start-2 md:col-span-10">
                            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-8 pb-16 mt-5 ">
                                <form id="create-course-form" method='post' onSubmit={handleSubmit}>
                                    <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[15px]">
                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Name *</label><br/>
                                            <input onChange={(e) => {setName(e.target.value)}} className="form-control" type="text" name="name"  placeholder="Your Name" required=""/>                                
                                        </div>

                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Institute *</label><br/>
                                            <input onChange={(e) => {setInstitute(e.target.value)}} className="form-control" type="text" name="institute"  placeholder="Your Institute *" required=""/>                                
                                        </div>

                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Mobile No *</label><br/>
                                            <input onChange={(e) => {setPhone(e.target.value)}} className="form-control" type="number" name="phone"  placeholder="Your Mobile No " required=""/>                                
                                        </div>

                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Email *</label><br/>
                                            <input onChange={(e) => {setEmail(e.target.value)}} className="form-control" type="email" name="email"  placeholder="Your Email" required=""/>                                
                                        </div>
                                    </div>

                                    <div className="grid md:grid-cols-1 grid-cols-1 mt-8 gap-[15px]">
                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Subject  *</label><br/>
                                            <input onChange={(e) => {setSubject(e.target.value)}} className="form-control" type="text" name="subject"  placeholder="Subject " required=""/>                                
                                        </div>

                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Description *</label><br/>
                                            <textarea onChange={(e)=>{setDescription(e.target.value)}} className='get-started-textarea'></textarea>                              
                                        </div>

                                        <div >
                                            <iframe title="reCAPTCHA" width="304" height="78" role="presentation" name="a-o4xme9qtm3n3" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Leu_7EnAAAAAIDRCAUKLQaYM2Ma3MtcXE76D0Kx&amp;co=aHR0cHM6Ly9lZHVrcnlwdC5jb206NDQz&amp;hl=en-GB&amp;v=KXX4ARWFlYTftefkdODAYWZh&amp;size=normal&amp;cb=c7byxf2w36an"></iframe>
                                        </div>
                                    </div>

                                    <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                                        <div className="form-group text_box text-left">
                                            <button type='submit' className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full">Submit</button>

                                        </div>

                                       
                                    </div>
                                    

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





            <Footer />
            <CookieModal />
        </>
    )
}
