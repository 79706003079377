import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import about2 from '../../assets/images/company/about2.png';
import cta_bg from '../../assets/images/cta-bg.jpg';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
import Blog2 from '../../component/blog2';
import imageP from '../../assets/images/portfolio/01.jpg';
import image1 from '../../assets/images/portfolio/02.jpg';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';
import KeyFeature from '../../component/keyFeature';
import KeyFeatureTwo from '../../component/keyFeatureTwo';
import app from '../../assets/images/app/app.png';
import playstore from '../../assets/images/app/playstore.png';
import hero from '../../assets/images/course/hero.png';

import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";

import * as Icon from 'react-feather';
import { FaArrowRight, MdKeyboardArrowRight } from '../../assets/icons/icons'


import { featureData } from '../../data/dataFour';
import { accordionData } from '../../data/dataTwo';
import { TypeAnimation } from 'react-type-animation';


export default function IndexLandingFour() {
    let [isOpen, setOpen] = useState(false)
    let [activeIndex, setActiveIndex] = useState(0);


    let toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 lg:py-44 bg-indigo-600/5 overflow-hidden">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px] relative">
                        <div className="md:col-span-7">
                            <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">Manage Multiple<br /><span className="text-indigo-600">Locations</span>  Seamlessly</h4>
                            <p className="text-slate-400 text-lg max-w-xl">Leading POS software designed to empower retailers and shop owners with an all-in-one solution to streamline operations and boost sales. </p>

                            <div className="mt-6">
                                <Link to="/get-started"  className="py-2 px-5  inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link>
                            </div>
                        </div>

                        <div className="md:col-span-5">
                            <div className="relative">
                                <img src='images/business/banner.png' alt="" />
                            </div>
                        </div>

                        <div className="overflow-hidden after:content-[''] after:absolute after:size-32 after:bg-red-600/5 after:top-10 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]"></div>
                        <div className="overflow-hidden after:content-[''] after:absolute after:size-[512px] after:bg-indigo-600/5 after:top-0 after:-end-5 after:-z-1 after:rounded-full"></div>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px] relative">
                        <div className="lg:col-span-12 md:col-span-12">
                            <div className="grid grid-cols-1 pb-8">
                                <h3 className="mb-4 md:text-3xl md:leading-normal leading-normal font-semibold text-center width100">Expand Your Business Without Limits</h3>

                                <p className="text-slate-400  mt-2 width80 m-auto text-center">Scale Up Smoothly with Our Multi-Business POS Software                                </p>
                            </div>

                            <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                                <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                                    {/* <Icon.Users className="size-12 stroke-1 text-indigo-600"></Icon.Users> */}
                                    <img src="images/icons/retail-store.png" alt="" class="appIcon icon-orange"></img>

                                    <div className="content mt-4">
                                        <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Retail Stores</Link>
                                        <p className="text-slate-400 mt-3">From clothing boutiques to electronics stores, manage your business efficiently.</p>


                                    </div>
                                </div>

                                <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                                    {/* <Icon.Users className="size-12 stroke-1 text-indigo-600"></Icon.Users> */}
                                    <img src="images/icons/cafe.png" alt="" class="appIcon icon-orange"></img>


                                    <div className="content mt-4">
                                        <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Cafés and Restaurants</Link>
                                        <p className="text-slate-400 mt-3">Streamline orders, manage tables, and enhance customer service.</p>


                                    </div>
                                </div>

                                <div className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                                    {/* <Icon.Users className="size-12 stroke-1 text-indigo-600"></Icon.Users> */}
                                    <img src="images/icons/store3.png" alt="" class="appIcon icon-orange"></img>


                                    <div className="content mt-4">
                                        <Link to="#" className="title h5 text-lg font-medium hover:text-indigo-600">Franchise Chains</Link>
                                        <p className="text-slate-400 mt-3">Centralized control over multiple locations with ease</p>


                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="overflow-hidden after:content-[''] after:absolute after:h-40 after:w-40 after:bg-red-600/5 after:top-2/4 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]"></div>
                        <div className="overflow-hidden after:content-[''] after:absolute after:size-[512px] after:bg-indigo-600/5 after:top-1/4 after:end-0 after:-z-1 after:rounded-full"></div>
                    </div>
                </div>
            </section>

            <section className="relative pb-16 ">
                <div className="container relative">
                    <div className='text-center pb-16'>
                        {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
                        <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Why Choose Us? </h3>
                        <p class="text-slate-400  mt-2 width80 m-auto">We offer a versatile and user-friendly solution tailored to meet the unique requirements <br/>of multiple businesses and shops.  </p>
                    </div>
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">

                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Multi-Store Management</h3>
                                <ul className='list-none text-slate-400 mt-4'>
                                    <li class="mb-1 flex items-center ms-0 mt-2">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg>   Effortlessly manage multiple locations from a single platform.
                                    </li>

                                    <li class="mb-1 flex items-center ms-0 mt-2">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg>  Real-time inventory tracking across all stores.
                                    </li>

                                    <li class="mb-1 flex items-center ms-0 mt-2">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg> Centralized reporting for comprehensive business insights.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="lg:col-span-5 p-2 border shadow radius-4">
                            <img src='images/business/img2.jpg' alt="" />
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
                        <div className="lg:col-span-5 p-2 border shadow radius-4">
                            <img src="images/business/img1.jpg" alt="" />
                        </div>
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">

                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">User-Friendly Interface</h3>
                                <ul className='list-none text-slate-400 mt-4'>
                                    <li class="mb-1 flex items-center ms-0 mt-2">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg>  Intuitive design for quick and easy transactions.
                                    </li>

                                    <li class="mb-1 flex items-center ms-0 mt-2">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg> Customizable layouts to suit your business needs.
                                    </li>

                                    <li class="mb-1 flex items-center ms-0 mt-2">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg> Mobile compatibility for on-the-go management.
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </div>

                    <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">

                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Advanced Inventory Management</h3>
                                <ul className='list-none text-slate-400 mt-4'>
                                    <li class="mb-1 flex items-center ms-0 mt-2">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg>  Stock level updates and low-stock alerts.
                                    </li>

                                    <li class="mb-1 flex items-center ms-0 mt-2">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg> Bulk inventory import and export options.
                                    </li>

                                    <li class="mb-1 flex items-center ms-0 mt-2">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg> Detailed product categorization and barcode scanning.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="lg:col-span-5 p-2 border shadow radius-4">
                            <img src="images/business/img9.jpg" alt="" />
                        </div>


                    </div>

                    <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
                        <div className="lg:col-span-5 p-2 border shadow radius-4">
                            <img src="images/business/img8.jpg" alt="" />
                        </div>
                        <div className="lg:col-span-7">
                            <div className="lg:ms-10">

                                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Seamless Sales Processing</h3>
                                <ul className='list-none text-slate-400 mt-4'>
                                    <li class="mb-1 flex items-center ms-0 mt-2">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg>  Fast and secure checkout process.
                                    </li>

                                    <li class="mb-1 flex items-center ms-0 mt-2">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg> Support for various payment methods (cash, credit, digital wallets).
                                    </li>

                                    <li class="mb-1 flex items-center ms-0 mt-2">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                        </svg> Integrated receipt printing and email receipts.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            {/* <section className="relative md:py-24 py-16 bg-gray-50">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
                        <div className="lg:col-span-8 md:col-span-6 md:text-start text-center">
                            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Explore Latest Works</h3>
                            <p className="text-slate-400 max-w-xl">Start working with Techwind that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>

                        <div className="lg:col-span-4 md:col-span-6 md:text-end hidden md:block">
                            <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">See More <FaArrowRight className="ms-2 text-[10px]" /></Link>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                        <div className=" picture-item rounded-md group  rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
                            <div className="">
                                <div className="relative">
                                    <div className=" dark:shadow-gray-800 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                        <img src='images/business/img5.jpg' className="rounded-t-md shadow " alt="" />
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className=" picture-item rounded-md group  rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
                            <div className="">
                                <div className="relative">
                                    <div className=" dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/business/img7.jpg' className="rounded-t-md shadow " alt="" />
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className=" picture-item rounded-md group  rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
                            <div className="">
                                <div className="relative">
                                    <div className=" dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/business/img4.jpg' className="rounded-t-md shadow " alt="" />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section className='py-16 mt-7 w-full table relative bg-indigo-600 mb-16'>
                <div className='container'>
                    <h3 class="mb-4 md:text-4xl text-3xl lg:leading-normal leading-normal text-white font-semibold text-center">Ready to Transform Your Business?</h3>
                    <p class="text-white/80 max-w-xl mx-auto lg:ms-auto  text-center">Book your demo today and experience the power of an integrated POS system designed to help you grow and succeed.</p>
                    <div class="mt-2 d-flex justify-content-center" style={{ justifyContent: "center", display: 'flex' }}>
                        <div className="mt-6">
                            <Link to="/contact-one" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Contact Us</Link>
                            {/* <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Start Free</Link> */}
                        </div>
                    </div>
                </div>
            </section>



            <Footer />
            <CookieModal />
        </>
    )
}
