import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import about2 from '../../assets/images/company/about2.png';
import cta_bg from '../../assets/images/cta-bg.jpg';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
import Blog2 from '../../component/blog2';
import imageP from '../../assets/images/portfolio/01.jpg';
import image1 from '../../assets/images/portfolio/02.jpg';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';
import KeyFeature from '../../component/keyFeature';
import KeyFeatureTwo from '../../component/keyFeatureTwo';


import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";

import * as Icon from 'react-feather';
import { FaArrowRight, MdKeyboardArrowRight } from '../../assets/icons/icons'


import { featureData } from '../../data/dataFour';
import { accordionData } from '../../data/dataTwo';
import { TypeAnimation } from 'react-type-animation';


export default function IndexLandingFour() {
  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);


  let toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  }
  return (
    <>
      <Navbar />
      <section className="relative table w-full pt-24 pb-24 bg-gray-50 dark:bg-slate-800 overflow-hidden" id="home">
        <div className="container relative">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 mt-10 items-center gap-[30px]">
            <div className="lg:col-span-6 mt-9">
              {/* <span className="bg-indigo-600/5 text-indigo-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">Form Landing Page</span> */}

              {/* <h4 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mt-2 mb-5">Cutting-Edge POS <br/>Software for, </h4> */}
              <h1 className="font-bold heading lg:leading-normal leading-normal text-5xl lg:text-5xl mb-5">Streamline Your Inventory with Ease</h1>

              <p className="text-slate-400 text-lg max-w-xl text-justify">Our software is a powerful, intuitive inventory management software designed to help businesses of all sizes efficiently manage their stock, reduce waste, and increase profitability.</p>

              <div className="relative mt-10">
                <Link to="/get-started"  className="py-2 px-5  inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link>
                {/* <Link className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full me-5 ms-4"> Support Training</Link> */}

              </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} />

            <div className="lg:col-span-6">
              <div className="dark:bg-slate-900 rounded-xl  dark:shadow-gray-800 md:p-8 p-6 lg:ms-10 z-10 relative">
                {/* <h4 className="mb-5 text-2xl font-semibold">Just Trial Form</h4> */}

                  <div className="relative after:content-[''] after:absolute after:md:bottom-12 after:-bottom-20 lg:after:-start-0 md:after:-start-20 after:-start-24
                    after:bg-indigo-600 after:shadow-2xl after:shadow-indigo-600/40 after:-z-1 ltr:after:rotate-[130deg] rtl:after:-rotate-[130deg] after:w-[75rem] after:md:h-[55rem] after:h-[30rem] after:rounded-[20rem]">
                        <img src='images/inventory/inventory3.jpg' className='p-3 border shadow radius-4'></img>
                    </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container relative mt-24">
        <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
          <div className="lg:col-span-12 md:col-span-12 md:text-start text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">Streamline Your Inventory, Maximize Your Efficiency </h3>
            <p className="text-slate-400  mt-2 width80 m-auto text-center">Implementing inventory management software not only enhances operational efficiency and accuracy but also supports better strategic planning, customer satisfaction, and business growth.</p>
          </div>
        </div>


        <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                  <img src='images/inventory/inventory1.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl">Increase Efficiency</h5>
                <span className="text-slate-400 text-justify">Automate routine tasks and streamline operations.</span>
              </div>
            </div>
          </div>

          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                  <img src='images/inventory/inventory2.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl">Reduce Costs</h5>
                <span className="text-slate-400 text-justify">Minimize overstock, leading to better cash flow management.</span>
              </div>
            </div>
          </div>

          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                  <img src='images/inventory/inventory4.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl">Improve Accuracy</h5>
                <span className="text-slate-400 text-justify">Reduce human error with precise, automated tracking and updates.</span>
              </div>
            </div>
          </div>

          <div className=" picture-item p-4 rounded-md group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 transition-all duration-500 ease-in-out">
            <div className="">
              <div className="relative">
                <div className="shadow dark:shadow-gray-800 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                  <img src='images/inventory/inventory5.jpg' className="rounded-t-md shadow product_services_img" alt="" />
                </div>
              </div>

              <div className="pt-4 px-3">
                <h5 className="mb-1 font-semibold text-xl">Enhance Customer Satisfaction</h5>
                <span className="text-slate-400 text-justify">Ensure product availability and proper timely deliveries.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container relative mt-24">
        <div className="grid grid-cols-1 pb-8 text-center">
          {/* <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Features</h6> */}
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Comprehensive Inventory Management Features</h3>

          <p className="text-slate-400  mx-auto">Experience seamless inventory management like never before.</p>
        </div>

        <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/inventory/tracking.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/" className="title h5 text-lg font-medium hover:text-indigo-600">Real-Time Tracking</Link>
              {/* <p className="text-slate-400 mt-3">Streamline your operations and drive business growth.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/inventory/reorder.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/" className="title h5 text-lg font-medium hover:text-indigo-600">Automated Reordering</Link>
              {/* <p className="text-slate-400 mt-3">Track stock levels and manage suppliers seamlessly.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/inventory/integration.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/" className="title h5 text-lg font-medium hover:text-indigo-600">Multi-Channel Integration</Link>
              {/* <p className="text-slate-400 mt-3">Monitor data and payroll of  employees</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/inventory/report.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/" className="title h5 text-lg font-medium hover:text-indigo-600">Detailed Reporting</Link>
              {/* <p className="text-slate-400 mt-3">Quick and easy transactions data, minimizing training time.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/inventory/barcode.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/" className="title h5 text-lg font-medium hover:text-indigo-600">Barcode Scanning</Link>
              {/* <p className="text-slate-400 mt-3">Ensure secure payment processing with support.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>

          <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <img src='images/inventory/interface.png' alt="" className='appIcon' />
            </div>

            <div className="content mt-7">
              <Link to="/" className="title h5 text-lg font-medium hover:text-indigo-600">User-Friendly Interface</Link>
              {/* <p className="text-slate-400 mt-3">Monitor sales, inventory, and staff across all your locations.</p> */}

              {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
            </div>
          </div>
        </div>
      </div>

      <section className="relative md:py-24 py-16 ">
        <div className="container relative">
          <div className='text-center pb-16'>
            {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
            <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Why Choose Us? </h3>
            <p class="text-slate-400  mt-2 width80 m-auto">Our platform offers seamless integration with your existing systems, ensuring a smooth transition and minimal disruption to your operations.</p>
          </div>
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Solution Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Integration</h3>
                <ul className='list-none text-slate-400 mt-4'>
                  <li class="mb-1 flex items-center ms-0"><b>System Integration</b>:</li>
                  <li class="mb-1 flex items-center ms-0 mt-2 d-flex ">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" className='svg-top'>
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>   
                    <p class="text-slate-400  mt-2 width80">Seamlessly integrates with other business software (e.g., ERP, CRM), enhancing overall operational efficiency.</p>
                  </li>

                  <li class="mb-1 flex items-center ms-0 mt-4"><b>Multi-channel Management</b>: </li>
                  <li class="mb-1 flex items-center ms-0 mt-2 d-flex">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"className='svg-top' >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg> 
                    <p class="text-slate-400  mt-2 width80"> Manages inventory across multiple sales channels (e.g., online, retail stores) from a single platform.</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src='images/inventory/inventory6.jpg' alt="" />
            </div>
          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/inventory/inventory7.jpg" alt="" />
            </div>
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Scalability</h3>
                <ul className='list-none text-slate-400 mt-4'>
                  <li class="mb-1 flex items-center ms-0 mt-4"><b>Supports Growth</b>:</li>
                  <li class="mb-1 flex items-center ms-0 mt-4">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg> Easily adapts to changes in inventory levels as the business grows.
                  </li>

                  <li class="mb-1 flex items-center ms-0 mt-4"><b>Flexibility</b>: </li>
                  <li class="mb-1 flex items-center ms-0 mt-2 d-flex">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" className='svg-top'>
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>
                    <p class="text-slate-400  mt-2 width80">Can be customized to fit the specific needs of different industries and business sizes.</p>
                  </li>
                </ul>
                
              </div>
            </div>

          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Customer Satisfaction</h3>
                <ul className='list-none text-slate-400 mt-4'>
                  <li class="mb-1 flex items-center ms-0"><b>Faster Fulfillment</b>:</li>
                  <li class="mb-1 flex items-center ms-0 mt-2">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" className='svg-top'>
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>
                    <p class="text-slate-400  mt-2 width80">Ensures that products are available when customers need them, speeding up delivery times.</p>
                     
                  </li>

                  <li class="mb-1 flex items-center ms-0 mt-4"><b>Accurate Orders</b>: </li>
                  <li class="mb-1 flex items-center ms-0 mt-2 d-flex">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" className='svg-top'>
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>
                    <p class="text-slate-400  mt-2 width80">Reduces the chances of errors in order fulfillment, enhancing customer trust and loyalty.</p>
                    
                  </li>
                </ul>
                
              </div>
            </div>
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/inventory/inventory8.jpg" alt="" />
            </div>


          </div>

          <div className="grid lg:grid-cols-12 mt-16 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
            <div className="lg:col-span-5 p-2 border shadow radius-4">
              <img src="images/inventory/inventory9.jpg" alt="" />
            </div>
            <div className="lg:col-span-7">
              <div className="lg:ms-10">

                {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Enhanced Productivity</h3>
                
                <ul className='list-none text-slate-400 mt-4'>
                  <li class="mb-1 flex items-center ms-0"><b>Automation of Routine Tasks</b>:</li>
                  <li class="mb-1 flex items-center ms-0 mt-4">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg> Automates tasks such as reordering, inventory counting, and report generation.
                  </li>

                  <li class="mb-1 flex items-center ms-0 mt-4"><b>Streamlined Operations</b>: </li>
                  <li class="mb-1 flex items-center ms-0 mt-4">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-indigo-600 text-base me-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>Integrates with other existing systems, creating a cohesive workflow.
                  </li>
                </ul>
              </div>
            </div>
          </div>


        </div>
      </section>



      <section className='py-16 mt-9 w-full table relative bg-indigo-600 mb-12'>
        <div className='container'>
          <h3 class="mb-4 md:text-4xl text-3xl lg:leading-normal leading-normal text-white font-semibold text-center">Get Started Today</h3>
          <p class="text-white/80 max-w-xl mx-auto lg:ms-auto  text-center">Take control of your inventory management and unlock new levels of efficiency and profitability.</p>
          <div class="mt-2 d-flex justify-content-center" style={{ justifyContent: "center", display: 'flex' }}>
            <div className="mt-6">
              <Link to="/contact-one" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Contact Us</Link>
              {/* <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Start Free</Link> */}
            </div>
          </div>
        </div>
      </section>



      <Footer />
      <CookieModal />
    </>
  )
}
