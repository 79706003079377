import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import axios from "axios";

import about2 from '../../assets/images/company/about2.png';
import cta_bg from '../../assets/images/cta-bg.jpg';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
import Blog2 from '../../component/blog2';
import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';
import KeyFeature from '../../component/keyFeature';

import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";

import * as Icon from 'react-feather';
import {FaArrowRight,MdKeyboardArrowRight} from '../../assets/icons/icons'

import TinySlider from 'tiny-slider-react';
import { feedback } from '../../data/data';


import { featureData } from '../../data/dataFour';
import { accordionData } from '../../data/dataTwo';
import { TypeAnimation } from 'react-type-animation';

import SweetAlert2 from 'react-sweetalert2';
import Swal from 'sweetalert2'

import { nftData } from '../../data/data';
import {creatorsData} from '../../data/data'
export default function IndexLandingFour() {
  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);

  const [name,setName] = useState();
  const [email,setEmail] = useState();
  const [organisation,setOrganisation] = useState();
  const [phone,setPhone] = useState();


    let toggleAccordion = (index) => {
      if (activeIndex === index) {
          setActiveIndex(0);
      } else {
          setActiveIndex(index);
      }
  }


  const [swalProps, setSwalProps] = useState({});


  const handleSubmit = (event) => {
    event.preventDefault();


        let data = JSON.stringify({
        "name": name,
        "email": email,
        "organisation": organisation,
        "phone": phone
        });

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://edukrypt.com/api/sendEmailPoskrypt',
        headers: {
            'Content-Type': 'application/json'
        },
        data : data
        };

        axios.request(config)
        .then((response) => {

            document.getElementById("create-course-form").reset();
            Swal.fire(response.data.message);



        })
        .catch((error) => {
        console.log(error);
        });


  };

  const settings = {
    container: '.tiny-five-item',
    controls: true,
    mouseDrag: true,
    
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
    nav: false,
    speed: 400,
    gutter: 0,
    responsive: {
        1025: {
            items: 3
        },

        992: {
            items: 4
        },

        767: {
            items: 3
        },

        425: {
            items: 1
        },
    },
}

const settings2 = {
    container: '.tiny-five-item',
    controls: true,
    mouseDrag: true,
    
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
    nav: false,
    speed: 400,
    gutter: 0,
    responsive: {
        1025: {
            items: 2
        },

        992: {
            items: 4
        },

        767: {
            items: 3
        },

        425: {
            items: 1
        },
    },
}


    return (
        <>
            <Navbar />
            <section className="relative table w-full pt-24 pb-24 bg-gray-50 dark:bg-slate-800 " id="home">
                <div className="container relative">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 mt-10 items-center gap-[30px]">
                        <div className="lg:col-span-7">
                            {/* <span className="bg-indigo-600/5 text-indigo-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">Form Landing Page</span> */}

                            {/* <h4 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mt-2 mb-5">Cutting-Edge POS <br/>Software for, </h4> */}
                            <h1 className="font-bold lg:leading-normal heading leading-normal text-5xl lg:text-5xl mb-5">Unlock Your Potential with<br/> Our Expert Coaching                        </h1>

                            <p className="text-slate-400 text-lg max-w-xl text-justify width90">Welcome to ICP Shiksha , where excellence meets opportunity. Our coaching institute is dedicated to guiding aspiring chartered accountants towards success.</p>

                            <div className="relative mt-10">
                                {/* <Link to="/get-started" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full me-1">Get Started</Link> */}
                                {/* <Link className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full me-5 ms-4"> Support Training</Link> */}
                                <Link to="/get-started"  className="py-2 px-5  inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link>
                            </div>
                        </div>
                        

                        <div className="md:col-span-5">
                            <div className="relative">
                                <img src='images/course/banner.png' alt="" />
                            </div>
                        </div>

                        <div className="overflow-hidden after:content-[''] after:absolute after:size-32 after:bg-red-600/5 after:top-10 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]"></div>
                        <div className="overflow-hidden after:content-[''] after:absolute after:size-[512px] after:bg-indigo-600/5 after:top-0 after:-end-5 after:-z-1 after:rounded-full"></div>
                    </div>
                </div>
            </section>


            <section className="relative pt-2 pb-24  dark:bg-slate-800 mt-7">
                <div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                        <div className="relative">
                            <div className="md:me-10">
                                <img src='images/course/aboutus.png' className="" alt="" />
                                <h5 className="mb-6 md:text-2xl md:leading-normal text-2xl text-center leading-normal font-semibold">Discover the ICP Shiksha advantage today!</h5>

                            </div>
                            
                        </div>

                        <div className="lg:ms-8 mt-8 md:mt-0">
                            <h4 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">About ICP Shiksha  </h4>
                            <p className="text-slate-400 text-justify"><b>Welcome to ICP Shiksha!</b>At ICP Shiksha, we have been dedicated to providing top-notch coaching for Chartered Accountancy (CA) and Company Secretary (CS) aspirants for the past 14 years. Founded by CA Himanshu Grover, our institute has emerged as a trusted name in the field of professional education.</p>
                            <p className="text-slate-400 mt-4 text-justify">Our mission is to empower students with the knowledge, skills, and confidence needed to excel in their CA and CS examinations. With a team of experienced faculty members and a proven track record of success, we strive to deliver comprehensive and result-oriented coaching that caters to the unique learning needs of each student.</p>
                            <p className="text-slate-400 mt-4 text-justify">What sets us apart is our commitment to excellence, personalized attention to students, and a holistic approach to learning. We believe in not just imparting theoretical knowledge but also in nurturing critical thinking, problem-solving abilities, and professional ethics among our students.</p>
                            {/* <p className="text-slate-400 mt-4">Whether you're a beginner embarking on your CA/CS journey or a seasoned candidate aiming for that final push towards success, ICP Shiksha is here to support you every step of the way. Join us and let's embark on this educational journey together towards a brighter future.</p> */}

                            {/* <div className="mt-4">
                                <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

        {/* <!-- Start Section--> */}
        <section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
            <div class="container relative">
                <div className="grid grid-cols-1 pb-8 text-center">
                    {/* <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Features</h6> */}
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Browse Online Course Categories </h3>

                    <p className="text-slate-400  mx-auto">Search your future opportunity with our categories</p>
                </div>
            </div>

            <div class="container relative">
                <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
                        <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
                            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                  <img src='images/course/icon1.png' alt="" className='appIcon'/>
                            </div>

                            <div className="content mt-7">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">CMA</Link>
                                <p className="text-slate-400 mt-3">10 Courses</p>

                                {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
                            </div>
                        </div>

                        <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
                            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                  <img src='images/course/icon2.png' alt="" className='appIcon'/>
                            </div>

                            <div className="content mt-7">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">CA Foundation</Link>
                                <p className="text-slate-400 mt-3">10 Courses</p>

                                {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
                            </div>
                        </div>

                        <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
                            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                  <img src='images/course/icon1.png' alt="" className='appIcon'/>
                            </div>

                            <div className="content mt-7">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">CA Foundation</Link>
                                <p className="text-slate-400 mt-3">10 Courses</p>

                                {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
                            </div>
                        </div>

                        <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
                            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                  <img src='images/course/icon2.png' alt="" className='appIcon'/>
                            </div>

                            <div className="content mt-7">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">CMA</Link>
                                <p className="text-slate-400 mt-3">1 Course</p>

                                {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
        </section>



        <section class="relative md:py-24 py-16 overflow-hidden" id="courses">
            <div class="container relative">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Popular Courses</h3>
                    <p class="text-slate-400 max-w-xl mx-auto">Explore Our Comprehensive CA Exam Preparation Programs"</p>
                </div>

                <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">
                    <div class="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                        <a href="/course_details">
                        <div class="relative overflow-hidden">
                            <img src="images/course/course1.jpeg" class="group-hover:scale-110 duration-500 ease-in-out" alt=""/>
                            <div class="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>

                            <div class="absolute start-0 bottom-0 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                                <div class="pb-4 ps-4 flex items-center">
                                    {/* <!-- <img src="assets/images/client/01.jpg" class="size-12 rounded-full shadow-md dark:shadow-gray-800 mx-auto" alt=""/> --> */}
                                    <div class="ms-3">
                                        {/* <!-- <a href="#" class="font-semibold text-white block">Calvin Carlo</a> --> */}
                                        {/* <!-- <span class="text-white/70 text-sm">Professor</span> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="content p-6 relative">
                            <a href="#" class="font-medium block text-indigo-600">CA Foundation</a>
                            <a href="#" class="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2"> Business Economics for Jan 2025</a>
                            <Link to="/checkout" type="submit" id="submitsubscribe" name="send" class=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Buy now</Link>
                            <div class="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                <div class="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >
                                    <span class="font-semibold" >₹ 6000</span>
                                </div>
                            </div>
                        </div>
                        </a>
                    </div>
                    
                    <div class="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                    <a href="/course_details">
                        <div class="relative overflow-hidden">
                            <img src="images/course/course9.jpeg" class="group-hover:scale-110 duration-500 ease-in-out" alt=""/>
                            <div class="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
                        </div>
                        </a>


                        <div class="content p-6 relative">
                            <a href="#" class="font-medium block text-indigo-600">CA Foundation</a>
                            <a href="#" class="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2"> Accounting for Jan 2025</a>
                                <Link to="/checkout" type="submit" id="submitsubscribe" name="send" class=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Buy now</Link>
                                <div class="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                    <div class="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >
                                    <span class="font-semibold">₹ 6000</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div class="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                        <a href="/course_details">
                            <div class="relative overflow-hidden">
                                <img src="images/course/course2.jpeg" class="group-hover:scale-110 duration-500 ease-in-out" alt=""/>
                                <div class="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
                            </div>
                        </a>

    
                            <div class="content p-6 relative">
                                <a href="#" class="font-medium block text-indigo-600">CA Foundation</a>
                                <a href="#" class="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2"> Quantitative Aptitude for Jan 2025</a>
                                    <Link to="/checkout" type="submit" id="submitsubscribe" name="send" class=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Buy now</Link>
                                        <div class="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                        <div class="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >
                                        <span class="font-semibold">₹ 6000</span>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                    
                    <div class="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                        <a href="/course_details">

                        <div class="relative overflow-hidden">
                            <img src="images/course/course3.jpeg" class="group-hover:scale-110 duration-500 ease-in-out" alt=""/>
                            <div class="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
                        </div>
                        </a>


                        <div class="content p-6 relative">
                            <a href="#" class="font-medium block text-indigo-600">CA Foundation </a>
                            <a href="#" class="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2">Business Laws for Jan 2025</a>
                            <Link to="/checkout" type="submit" id="submitsubscribe" name="send" class=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Buy now</Link>

                                <div class="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                <div class="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >
                                    <span class="font-semibold">₹ 6000</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                        <a href="/course_details">

                        <div class="relative overflow-hidden">
                            <img src="images/course/course6.jpeg" class="group-hover:scale-110 duration-500 ease-in-out" alt=""/>
                            <div class="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
                        </div>

                        <div class="content p-6 relative">
                            <a href="#" class="font-medium block text-indigo-600">CA Foundation</a>
                            <a href="#" class="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2"> Combo for Jan 2025</a>
                            <Link to="/checkout" type="submit" id="submitsubscribe" name="send" class=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Buy now</Link>
                                <div class="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                <div class="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >
                                    <span class="font-semibold">₹ 20000</span>
                                </div>
                            </div>
                        </div>
                        </a>
                    </div>
                    
                    <div class="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                    <a href="/course_details">
                        <div class="relative overflow-hidden">
                            <img src="images/course/course4.jpeg" class="group-hover:scale-110 duration-500 ease-in-out" alt=""/>
                            <div class="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>

                        </div>

                        <div class="content p-6 relative">
                            <a href="#" class="font-medium block text-indigo-600">CA Foundation</a>
                            <a href="#" class="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2"> Business Economics for Sep 2024</a>
                            <Link to="/checkout" type="submit" id="submitsubscribe" name="send" class=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Buy now</Link>

                            <div class="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                <div class="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >
                                    <span class="font-semibold">₹ 6000</span>
                                </div>
                            </div>
                        </div>
                        </a>
                        
                    </div>

                    <div class="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                    <a href="/course_details">
                        <div class="relative overflow-hidden">
                            <img src="images/course/course10.jpeg" class="group-hover:scale-110 duration-500 ease-in-out" alt=""/>
                            <div class="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
                        </div>

                        <div class="content p-6 relative">
                            <a href="#" class="font-medium block text-indigo-600">CA Foundation</a>
                            <a href="#" class="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2"> Accounting for Sep 2024</a>
                            <Link to="/checkout" type="submit" id="submitsubscribe" name="send" class=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Buy now</Link>
                            <div class="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                <div class="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >
                                    <span class="font-semibold">₹ 6000</span>
                                </div>
                            </div>
                        </div>
                        </a>
                    </div>

                    <div class="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                        <a href="/course_details">
                        <div class="relative overflow-hidden">
                            <img src="images/course/course7.jpeg" class="group-hover:scale-110 duration-500 ease-in-out" alt=""/>
                            <div class="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
                        </div>

                        <div class="content p-6 relative">
                            <a href="#" class="font-medium block text-indigo-600">CA Foundation</a>
                            <a href="#" class="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2"> Quantitative Aptitude for Sep 2024</a>
                            <Link to="/checkout" type="submit" id="submitsubscribe" name="send" class=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Buy now</Link>
                            <div class="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                <div class="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >
                                    <span class="font-semibold">₹ 6000</span>
                                </div>
                            </div>
                        </div>
                        </a>
                    </div>

                    <div class="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                    <a href="/course_details">
                        <div class="relative overflow-hidden">
                            <img src="images/course/course5.jpeg" class="group-hover:scale-110 duration-500 ease-in-out" alt=""/>
                            <div class="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
                        </div>

                        <div class="content p-6 relative">
                            <a href="#" class="font-medium block text-indigo-600">CA Foundation</a>
                            <a href="#" class="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2"> Business Laws for Sep 2024</a>
                            <Link to="/checkout" type="submit" id="submitsubscribe" name="send" class=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Buy now</Link>
                            <div class="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                <div class="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >
                                    <span class="font-semibold">₹ 6000</span>
                                </div>
                            </div>
                        </div>
                        </a>
                    </div>

                    <div class="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                        <a href="/course_details">
                        <div class="relative overflow-hidden">
                            <img src="images/course/course8.jpeg" class="group-hover:scale-110 duration-500 ease-in-out" alt=""/>
                            <div class="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
                        </div>
                        </a>


                        <div class="content p-6 relative">
                            <a href="#" class="font-medium block text-indigo-600">CA Foundation</a>
                            <a href="#" class="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2"> Combo for Sep 2024</a>
                            <Link to="/checkout" type="submit" id="submitsubscribe" name="send" class=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Buy now</Link>
                            <div class="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                <div class="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >
                                    <span class="font-semibold">₹ 20000 </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

            


            <section className="relative ">
                {/* <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Tailored Solutions for Every Industry </h3>
                        <p className="text-slate-400 max-w-xl mx-auto">Empowering Every Industry with Cutting-Edge POS software</p>
                    </div>

                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] mt-8">
                      <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/retail.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Departmental Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/supermarket.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Supermarket</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/supermaket.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Grocery Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/toy-shop.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Toy Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/stationery.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Stationery Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/repair.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Hardware Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/hygiene-products.png' className="rounded-t-md shadow industries_icon " alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Sanitary Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/market.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Liquor Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/retail.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Retail Outlet</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/clothes-shop.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Garment Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/shoes.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Footwear</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/clothes-shop (1).png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Fashion Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/pharmacy.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Pharmacy</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/jewelry-shop.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Jewellery Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/payment.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">POS</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/repair-services.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Automobile Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/bookstore.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Book Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/electronic-store.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Electrical Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/store (1).png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Electronics Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/support.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Service Center</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/hair-salon.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Salon/Spa</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/shop.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Mobile Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/store (1).png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Computer Store</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/repair-shop.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Repair Shop</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/manufacture.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Manufacturing</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/restaurant.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Restaurant</h4>
                            </div>
                        </div>
                    </div>

                    <div className="lg:col-span-4 md:col-span-6">
                        <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                            <div className="flex items-center justify-center h-[55px] min-w-[55px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                                <img src='images/icons/furniture-store.png' className="rounded-t-md shadow industries_icon industries_icon2" alt="" />
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-lg font-medium">Furniture Store</h4>
                            </div>
                        </div>
                    </div>
                  </div>
                </div> */}
            </section>

            {/* <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                    <div className="relative">
                        <img src='images/icons/check.png' className="max-w-[440px] mx-auto" alt="" />
                        <div className="overflow-hidden absolute lg:size-[400px] size-[320px] bg-indigo-600/5 bottom-0 start-0 rotate-45 -z-1 rounded-3xl"></div>
                    </div>

                    <div className="lg:ms-8">
                        <h4 className="mb-4 text-2xl leading-normal font-medium">Real Time Health  Monitoring</h4>
                        <p className="text-slate-400">Due to its widespread use as filler text for layouts, non-readability is of great importance: human perception</p>
                        <ul className="list-none  text-slate-400 mt-4">
                            <li className="mb-1 flex items-center ms-0"><i class="fa fa-check check_icon" aria-hidden="true"></i> Digital Marketing Solutions for Tomorrow</li>
                            <li className="mb-1 flex items-center ms-0"><i class="fa fa-check check_icon" aria-hidden="true"></i> Our Talented & Experienced Marketing Agency</li>
                            <li className="mb-1 flex items-center ms-0"><i class="fa fa-check check_icon" aria-hidden="true"></i> Create your own skin to match your brand</li>
                            <li className="mb-1 flex items-center ms-0"><i class="fa fa-check check_icon" aria-hidden="true"></i> Create your own skin to match your brand</li>
                            <li className="mb-1 flex items-center ms-0"><i class="fa fa-check check_icon" aria-hidden="true"></i> Create your own skin to match your brand</li>
                        </ul>

                        <div className="mt-4">
                            <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl ms-1"/></Link>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Why Choose Us?</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">From inventory management to reporting, Flavour offers a comprehensive suite of features tailored to meet the unique needs of your business.</p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 mt-8 gap-[30px]">
                        <div className="text-center md:px-6 bg-gray border p-5 radius-4" >
                            <div className=' size-28  rounded-3xl text-4xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto'>
                                  <img src='images/icons/efficacy.png' alt="" className='width40 icon-orange'/>
                            </div>

                            <div className="content mt-7">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">Increased Efficiency</Link>
                                <p className="text-slate-400 mt-3 text-center">Speed up transactions and reduce wait times with our fast, reliable system.</p>
                            </div>
                        </div>

                        <div className="text-center md:px-6 bg-gray border p-5 radius-4" >
                            <div className=' size-28  rounded-3xl text-4xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto'>
                                  <img src='images/icons/accuracy.png' alt="" className='width40 icon-orange'/>
                            </div>

                            <div className="content mt-7">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">Enhanced Accuracy</Link>
                                <p className="text-slate-400 mt-3 text-center">Minimize errors with our automated processes and detailed reporting tools.</p>
                            </div>
                        </div>

                        <div className="text-center md:px-6 border p-5 radius-4" >
                            <div className=' size-28  rounded-3xl text-4xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto'>
                                  <img src='images/icons/solution.png' alt="" className='width50 icon-orange'/>
                            </div>

                            <div className="content mt-7">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">Scalable Solutions</Link>
                                <p className="text-slate-400 mt-3 text-center">Small business/Large enterprise: our software scales with your needs</p>
                            </div>
                        </div>

                        <div className="text-center md:px-6 bg-gray border p-5 radius-4" >
                            <div className=' size-28  rounded-3xl text-4xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 bg-gray mx-auto'>
                                <img src='images/icons/secure-payment.png' alt="" className='width50 icon-orange'/>
                            </div>

                            <div className="content mt-7">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">Secure Transactions</Link>
                                <p className="text-slate-400 mt-3 text-center"> Ensure data security with our robust encryption and secure payment processing.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

        <section className="relative ">
            <div className="container relative">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Our Community</h3>

                <p className="text-slate-400 max-w-xl mx-auto">See What Our Students Have to Say!!</p>
            </div>

            <div className="grid relative grid-cols-1 mt-8">
                <div className="tiny-two-item">

                <TinySlider settings2={settings2}>
                 
                        <div className="tiny-slide" >
                            <div className="lg:flex p-6 lg:p-0 relative rounded-md shadow dark:shadow-gray-800 overflow-hidden m-2" >
                                <img className="size-24 lg:w-48 lg:h-auto lg:rounded-none rounded-full mx-auto" src='images/course/profile.jpeg' alt="" width="384" height="512" />
                                <div className="pt-6 lg:p-6 text-center lg:text-start space-y-4">
                                <p className="text-base text-slate-400 text-justify">Game Changer for Our Restaurant!
                                    Switching to this POS system has streamlined our operations and improved our customer service.  </p>
        
                                <div>
                                    <span className="text-indigo-600 block mb-1">Aprajita</span>
                                    <span className="text-slate-400 text-sm dark:text-white/60 block">Owner</span>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="tiny-slide" >
                            <div className="lg:flex p-6 lg:p-0 relative rounded-md shadow dark:shadow-gray-800 overflow-hidden m-2" >
                                <img className="size-24 lg:w-48 lg:h-auto lg:rounded-none rounded-full mx-auto" src='images/course/profile.jpeg' alt="" width="384" height="512" />
                                <div className="pt-6 lg:p-6 text-center lg:text-start space-y-4">
                                <p className="text-base text-slate-400 text-justify">The customer support team is fantastic! They helped us set up and customize the system to fit our needs perfectly. The software is really reliable. </p>
        
                                <div>
                                    <span className="text-indigo-600 block mb-1">Ankit</span>
                                    <span className="text-slate-400 text-sm dark:text-white/60 block">Manager</span>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="tiny-slide" >
                            <div className="lg:flex p-6 lg:p-0 relative rounded-md shadow dark:shadow-gray-800 overflow-hidden m-2" >
                                <img className="size-24 lg:w-48 lg:h-auto lg:rounded-none rounded-full mx-auto" src='images/course/profile.jpeg' alt="" width="384" height="512" />
                                <div className="pt-6 lg:p-6 text-center lg:text-start space-y-4">
                                <p className="text-base text-slate-400 text-justify">The customer support team is fantastic! They helped us set up and customize the system to fit our needs perfectly. The software is really reliable. </p>
        
                                <div>
                                    <span className="text-indigo-600 block mb-1">Ankit</span>
                                    <span className="text-slate-400 text-sm dark:text-white/60 block">Manager</span>
                                </div>
                                </div>
                            </div>
                        </div>
            
                </TinySlider>
                
                </div>
            </div>
            </div>
        </section>


        <div class="container relative md:mt-24 mt-16 mb-12" id="Instructors">
            <div class="grid grid-cols-1 pb-8 text-center">
                <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Our Instructor</h3>

                <p class="text-slate-400 max-w-xl mx-auto">Meet Our Experienced Faculty Dedicated to Your Success</p>
            </div>

            <div class="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px] our-instructor">
                <div class="lg:col-span-3  m-auto justify-content-center">
                    <div class="group text-center">
                        <div class="relative inline-block mx-auto size-52 rounded-full overflow-hidden">
                            <img src="images/course/profile.jpeg" class="" alt=""/>
                            <div class="absolute inset-0 bg-gradient-to-b from-transparent to-black size-52 rounded-full opacity-0 group-hover:opacity-100 duration-500"></div>
                        </div>

                        <div class="content mt-3">
                            <a href="#" class="text-lg font-semibold hover:text-indigo-600 duration-500">CA Himanshu Grover</a>
                            <p class="text-slate-400">Professor</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    
            <Footer />
            <CookieModal />
        </>
    )
}
